import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalcRequest } from './response/calculation';
import { ActivatedRoute, Params, Router, UrlSegment, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MenuService } from './services/menu.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchService } from './services/search.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
    private subShow: Subscription;
    request: CalcRequest = new CalcRequest();
    navEnd: Observable<NavigationEnd>;
    formVisible = false;
    isHandset = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private menuService: MenuService,
        private searchService: SearchService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        this.navEnd = router.events.pipe(
            filter(evt => evt instanceof NavigationEnd)
        ) as Observable<NavigationEnd>;

        this.breakpointObserver.observe(['(max-width: 1125px)']).subscribe(result => {
            this.isHandset = result.matches;

            if (this.isHandset) {
                this.formVisible = false;
            } else if (this.router.isActive('/search', false)) {
                this.formVisible = true;
            }
        });

        iconRegistry.addSvgIcon(
            'construction',
            sanitizer.bypassSecurityTrustResourceUrl('assets/construction.svg')
        );

        this.subShow = this.searchService.missionHeaderSubject.subscribe((req: CalcRequest) => {
            this.formVisible = true;
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => this.request = CalcRequest.fromParams(params));
        this.navEnd.subscribe(evt => this.formVisible = !this.isHandset && this.router.isActive('/search', false));
    }

    ngOnDestroy() {
        this.subShow.unsubscribe();
    }

    toggleMenu() {
        this.menuService.toogleMenu();
    }
}
