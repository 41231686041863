import { Customization } from './customization';
import { Accessory } from '../services/accessory.service';

export class AccessoryCustomization implements Customization {
    private data: Accessory[] = [];

    getData(): Accessory[] {
        return this.data;
    }

    setData(data: Accessory[]) {
        this.data = data;
    }

    calculate(price: number): number {
        const prices = this.data.map((item: Accessory) => item.price * (item.influence / 100));
        return price + (prices.reduce((total: number, accPrice: number) => total + accPrice, 0) * 0.6);
    }

    isActive(): boolean {
        return this.data.length > 0;
    }
}
