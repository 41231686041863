import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent {
    rowHeight = '1:1';

    constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver.observe(['(max-width: 650px)']).subscribe(result => {
            if (result.matches) {
                this.rowHeight = '1:1';
            } else {
                this.rowHeight = '2:1';
            }
        });
    }
}
