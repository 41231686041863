import { Injectable } from '@angular/core';
import { Customization } from '../price-customizations/customization';
import { ConditionCustomization } from '../price-customizations/condition-customization';
import { AccessoryCustomization } from '../price-customizations/accessory-customization';
import { ActivatedRoute } from '@angular/router';
import { AccessoryService } from './accessory.service';
import { CalcRequest } from '../response/calculation';

@Injectable({
  providedIn: 'root'
})
export class PriceCustomizationService {
    private chain: Customization[] = [];

    constructor(
        private route: ActivatedRoute,
        private accService: AccessoryService
    ) {
        this.chain.push(new ConditionCustomization());
        this.chain.push(new AccessoryCustomization());

        this.get(AccessoryCustomization).setData(this.accService.loadActive());

        this.route.queryParams.subscribe(params => {
            const req = CalcRequest.fromParams(params);
            this.get(ConditionCustomization).setData(req.condition);
        });

        this.accService.dataChanged.subscribe(() => {
            this.get(AccessoryCustomization).setData(this.accService.loadActive());
        });
    }

    get(className: any): Customization {
        return this.chain.filter((item: Customization) => item instanceof className).pop();
    }

    calculate(priceToUpdate: number): number {
        return this.chain.reduce(
            (updatedPrice: number, item: Customization) => item.calculate(updatedPrice),
            priceToUpdate
        );
    }

    isActive(): boolean {
        let isActive = false;

        for (const c of this.chain) {
            if (c.isActive()) {
                isActive = true;
            }
        }

        return isActive;
    }
}
