import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchFormComponent } from '../search-form/search-form.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CalcRequest } from '../response/calculation';
import { SearchService } from '../services/search.service';
import { Subject, Subscription, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LanguageService } from '../services/language.service';
import { MatDialog } from '@angular/material';
import { ComingSoonDialogComponent } from '../detail/detail.component';

@Component({
  selector: 'search-form-header',
  templateUrl: './search-form-header.component.html',
  styleUrls: ['./search-form-header.component.css']
})
export class SearchFormHeaderComponent extends SearchFormComponent implements OnInit, OnDestroy {
    route: ActivatedRoute;
    request: CalcRequest;
    previous: CalcRequest;
    private subShow: Subscription;
    language: string;

    constructor(
        router: Router,
        route: ActivatedRoute,
        searchService: SearchService,
        breakpointObserver: BreakpointObserver,
        languageService: LanguageService,
        public dialog: MatDialog
    ) {
        super(router, searchService, breakpointObserver);
        this.route = route;
        this.request = new CalcRequest();
        this.previous = new CalcRequest();

        this.subscription = this.keyUp.pipe(
            debounceTime(150),
            distinctUntilChanged()
        ).subscribe((event: Event) => {
            this.searchService.run(this.request);
        });

        this.subShow = this.searchService.missionHeaderSubject.subscribe((req: CalcRequest) => {
            this.request = req;
        });

        this.language = languageService.locale;
    }

    onSubmit() {
        this.searchService.close();

        interval(350).pipe(
            take(1)
        ).subscribe(n => {
            super.onSubmit();
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            this.request = CalcRequest.fromParams(params);
            this.previous = CalcRequest.fromParams(params);
        });
    }

    comingSoon() {
        const dialogRef = this.dialog.open(ComingSoonDialogComponent, {width: '500px'});
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
