import { Component, Input } from '@angular/core';
import { CalcRequest } from '../response/calculation';
import { PriceCustomizationService } from '../services/price-customization.service';
import { Router } from '@angular/router';
import { ConditionCustomization } from '../price-customizations/condition-customization';
import { CountryAggregationsResponse } from '../response/elastic';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MenuService } from '../services/menu.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.css']
})
export class FilterFormComponent {
    @Input() request: CalcRequest;
    @Input() countryAggs: CountryAggregationsResponse;
    isHandset = false;
    language: string;

    constructor(
        private router: Router,
        private customizations: PriceCustomizationService,
        private breakpointObserver: BreakpointObserver,
        private menuService: MenuService,
        private languageService: LanguageService
    ) {
        this.language = this.languageService.locale;
        this.breakpointObserver.observe(['(max-width: 1124px)']).subscribe(result => {
            this.isHandset = result.matches;
        });
    }


    onManufacturerChange(manufacturer: string) {
        if (manufacturer) {
            const request = new CalcRequest();
            request.manufacturer = manufacturer;
            request.model = this.request.model;
            request.year = (new Date()).getFullYear() - 3;
            if (this.isHandset) {
                this.menuService.toogleMenu();
            }
            this.router.navigateByUrl(`/search?${request.toString()}`);
        }
    }

    onModelChange(model: string) {
        if (model) {
            const request = new CalcRequest();
            request.manufacturer = this.request.manufacturer;
            request.model = model;
            request.year = (new Date()).getFullYear() - 3;

            if (this.isHandset) {
                this.menuService.toogleMenu();
            }

            this.router.navigateByUrl(`/search?${request.toString()}`);
        }
    }

    onAgeChange(age: string) {
        this.request.year = (new Date()).getFullYear() - parseInt(age, 10);

        if (this.isHandset) {
            this.menuService.toogleMenu();
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }

    onUsageChange(usage: string) {
        this.request.usage = parseInt(usage, 10);

        if (this.isHandset) {
            this.menuService.toogleMenu();
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }

    onRegionChange(countries: Array<string>) {
        this.request.country = countries;
        this.request.batchModels = [];

        if (this.isHandset) {
            this.menuService.toogleMenu();
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }

    onCountryChange(newCountry: Array<string>) {
        this.request.country = newCountry;
        this.request.batchModels = [];

        if (this.isHandset) {
            this.menuService.toogleMenu();
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }

    onConditionChange(condition: string) {
        this.request.condition = condition;
        this.customizations.get(ConditionCustomization).setData(condition);

        if (this.isHandset) {
            this.menuService.toogleMenu();
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }
}
