import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {

    private menuToggle = new Subject<boolean>();

    missionToggled = this.menuToggle.asObservable();

    toogleMenu() {
        this.menuToggle.next(true);
    }
}
