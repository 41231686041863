import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
import { Subject } from 'rxjs';

export interface Accessory {
  name: string;
  inUse: boolean;
  price: number;
  influence: number;
}

const STORAGE_KEY = 'accessories';

@Injectable({
  providedIn: 'root'
})
export class AccessoryService {
  private changingData = new Subject<Accessory[]>();

  dataChanged = this.changingData.asObservable();

  constructor(private storage: LocalStorageService) { }

  save(accessories: Accessory[]): void {
    this.storage.set(STORAGE_KEY, accessories);
    this.changingData.next(this.loadActive());
  }

  load(): Accessory[] {
    return this.storage.get(STORAGE_KEY) || [];
  }

  loadActive(): Accessory[] {
    return this.load().filter((item: Accessory) => item.inUse);
  }
}
