import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
import { Subject } from 'rxjs';

export interface Margin {
  name: string;
  amount: number;
}

const STORAGE_KEY = 'margins';

@Injectable({
  providedIn: 'root'
})
export class MarginService {
  private changingData = new Subject<Margin[]>();

  dataChanged = this.changingData.asObservable();

  constructor(private storage: LocalStorageService) { }

  save(margins: Margin[]): void {
    this.storage.set(STORAGE_KEY, margins);
    this.changingData.next(margins);
  }

  load(): Margin[] {
    return this.storage.get(STORAGE_KEY) || [];
  }
}
