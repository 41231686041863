import { Component, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { LecturaModelSourceHit, YearAggregationsResponse, YearSource } from '../response/elastic';
import { ElasticService } from '../services/elastic.service';

@Component({
  selector: 'availability-graph',
  templateUrl: './availability-graph.component.html',
  styleUrls: ['./availability-graph.component.css']
})
export class AvailabilityGraphComponent {
    @Input() currentYear: number;
    avail: Chart;
    year: Chart;
    currentYearYear: number;
    currentYearCount: number;
    yearAvg: number;
    private _model: LecturaModelSourceHit;
    private _data: YearAggregationsResponse;
    private _yearSeriesData: [number, number][];

    constructor(private els: ElasticService) {  }

    @Input()
    set model(model: LecturaModelSourceHit) {
        const cY = this.currentYear || (new Date()).getFullYear() - 1;
        this._model = model;
        if (this._model) {
            this.els.getListingsYearCount(this._model.manufacturer, this._model.name).subscribe((response: YearAggregationsResponse) => {
                const year = response.aggregations.years.buckets.filter((item: YearSource) => item.key.toString() === cY.toString());
                this._data = response;
                this._yearSeriesData = [];

                for (const item of response.aggregations.years.buckets) {
                    this._yearSeriesData.push([item.key, item.doc_count]);
                }

                this.currentYearYear = year.length > 0 ? year[0].key : 0;
                this.currentYearCount = year.length > 0 ? year[0].doc_count : 0;
                this.yearAvg = this._yearSeriesData.length > 0
                    ? Math.round(this._yearSeriesData.reduce( ( p: number, c: Array<number>) => p + c[1], 0 ) / this._yearSeriesData.length)
                    : 0;
                this.createYearChart();
            });
        }
    }

    get model(): LecturaModelSourceHit {
        return this._model;
    }

    createYearChart() {
        this.year = new Chart({
            chart: {
                backgroundColor: '#fafafa',
                width: null,
                alignTicks: false,
                inverted: true
            },
            title: {
                text: ''
            },
            xAxis: {
                title: {
                    text: null
                },
                labels: {
                }
            },
            yAxis: [
                {
                    title: {
                        text: ''
                    }
                }, {
                    startOnTick: false,
                    min: 0,
                    gridLineWidth: 0,
                    title: {
                        text: ''
                    },
                    opposite: true
            }],
            legend: {
                enabled: false
            },
            series: [{
                type: 'column',
                name: 'Listings per year',
                data: this._yearSeriesData,
                color: '#ddd'
                }, {
                    type: 'line',
                    name: 'Machines',
                    yAxis: 1,
                    data: this._yearSeriesData,
                    color: '#fea140',
                }
            ]
        });
    }
}
