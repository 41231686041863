import { Component, Input } from '@angular/core';
import { LecturaModelSourceHit } from '../response/elastic';

class Breadcrumb {
    constructor(public title: string, public url: string) {  }
}

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
    private _model: LecturaModelSourceHit;
    public links: Breadcrumb[] = [];

    constructor() { }

    @Input()
    set model(model: LecturaModelSourceHit) {
        this._model = model;

        if (this._model) {
            this.links = [
                new Breadcrumb(this._model.manufacturer, `?q=${this._model.manufacturer}`),
                new Breadcrumb(this._model.name, `?q=${this.getQuery()}`)
            ];
        }
    }

    get model(): LecturaModelSourceHit { return this._model; }

    private getQuery() {
        return `${this._model.manufacturer}  ${this._model.name}`;
    }
}
