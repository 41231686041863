import { Component, Input } from '@angular/core';
import { SearchService } from '../services/search.service';
import { CalcRequest } from '../response/calculation';
import { ElasticService } from '../services/elastic.service';
import { LecturaModelResponse, LecturaModelSourceHit, LecturaModelSource } from '../response/elastic';
import { MatTableDataSource } from '@angular/material/table';
import { modelImage, yearsText, classonText } from '../utils/template';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { LanguageService } from '../services/language.service';

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.css'],
    animations: [
        trigger('openCloseMain', [
            state('open', style({
                height: 'calc(100% - 64px)'
            })),
            state('closed', style({
                height: 0
            })),
            transition('open => closed', [
                animate('0.3s 0ms ease-in')
            ]),
            transition('closed => open', [
                animate('0.3s 0ms ease-out')
            ])
        ])
    ]
})
export class SearchResultsComponent {
    @Input() disableCloseButton = false;
    displayedColumns: string[] = ['image', 'manufacturer', 'name', 'category', 'extra', 'years', 'classon'];
    dataSource: MatTableDataSource<LecturaModelSource> = new MatTableDataSource<LecturaModelSource>();
    isOpen = false;
    highlighted: string;

    constructor(
        private searchService: SearchService,
        private els: ElasticService,
        private router: Router,
        public language: LanguageService
    ) {
        this.searchService.missionSubject.subscribe((req: CalcRequest) => {
            this.dataSource.data = [];
            this.isOpen = true;
            this.els.getModelOptions(req.manufacturer, req.model, req.year).subscribe((res: LecturaModelResponse) => {
                this.dataSource.data = res.hits.hits;
            });
        });

        this.searchService.missionCloseSubject.subscribe((dummy: boolean) => {
            this.isOpen = false;
        });
    }


    imageUrl(image: string) {
        return modelImage(image);
    }

    years(model: LecturaModelSource): string {
        return yearsText(model._source.first_year, model._source.last_year);
    }

    classon(model: LecturaModelSource, locale: string): string {
        return classonText(model, locale);
    }

    highlight(row: LecturaModelSource) {
        this.highlighted = row._id;
    }

    unhighlight(row: LecturaModelSource) {
        if (this.highlighted === row._id) {
            this.highlighted = null;
        }
    }

    close() {
        this.isOpen = false;
    }

    redirect(row: LecturaModelSource) {
        this.isOpen = false;
        interval(350).pipe(
            take(1)
        ).subscribe(n => {
            const request = new CalcRequest();
            request.manufacturer = row._source.manufacturer;
            request.model = row._source.name;
            request.year = (new Date()).getFullYear() - 3;
            request.country = [];
            request.batchModels = [];
            request.usage = null;
            this.router.navigateByUrl(`/search?${request.toString()}`);
        });
    }
}
