export class Translation {
    constructor(
        public en: string,
        public de: string,
        public es: string,
        public it: string,
        public fr: string,
        public pl: string,
        public ru: string
    ) {  }
}

export class LecturaModelSourceHit {
    constructor(
        public id: number,
        public name: string,
        public manufacturer: string,
        public extra: string,
        public manufacturer_id: number,
        public first_year: number,
        public last_year: number,
        public tech_data_value: string,
        public td_unit: string,
        public category_name: Translation,
        public classon_name: Translation,
        public image: string,
        public specs_allowed: number,
        public valuation_allowed: number
    ) {  }
}

export class LecturaModelSource {
    constructor(
        public _source: LecturaModelSourceHit,
        public _score: number,
        public _id: string
    ) {  }
}

export class LecturaModelHit {
    constructor(
        public hits: LecturaModelSource[],
        public total: number
    ) {  }
}

export class CategorySource {
    constructor(
        public key: string,
        public doc_count: number
    ) {  }
}

export class CategoryAggregationsHit {
    constructor(
        public buckets: CategorySource[]
    ) {  }
}

export class CategoryAggregations {
    constructor(
        public categories: CategoryAggregationsHit
    ) {  }
}

export class LecturaModelResponse {
    constructor(
        public hits: LecturaModelHit,
        public _scroll_id: string
    ) {  }
}

export class CategoryAggregationsResponse {
    constructor(
        public hits: LecturaModelHit,
        public aggregations: CategoryAggregations
    ) {  }
}

export class YearSource {
    constructor(
        public key: number,
        public doc_count: number
    ) {  }
}

export class YearAggregationsHit {
    constructor(
        public buckets: YearSource[]
    ) {  }
}

export class YearAggregations {
    constructor(
        public years: YearAggregationsHit
    ) {  }
}

export class YearAggregationsResponse {
    constructor(
        public hits: LecturaModelHit,
        public aggregations: YearAggregations
    ) {  }
}

export class CountrySource {
    constructor(
        public key: string,
        public doc_count: number
    ) {  }
}

export class CountryAggregationsHit {
    constructor(
        public buckets: CountrySource[]
    ) {  }
}

export class CountryAggregations {
    constructor(
        public countries: CountryAggregationsHit
    ) {  }
}

export class CountryAggregationsResponse {
    constructor(
        public hits: LecturaModelHit,
        public aggregations: CountryAggregations
    ) {  }
}

export class ModelSource {
    constructor(
        public key: string,
        public doc_count: number
    ) {  }
}

export class ModelAggregationsHit {
    constructor(
        public buckets: ModelSource[]
    ) {  }
}

export class ModelAggregations {
    constructor(
        public models: ModelAggregationsHit
    ) {  }
}

export class ModelAggregationsResponse {
    constructor(
        public hits: LecturaModelHit,
        public aggregations: ModelAggregations
    ) {  }
}
