import { Component, Input } from '@angular/core';
import { PriceCustomizationService } from '../services/price-customization.service';
import { ConditionCustomization } from '../price-customizations/condition-customization';
import { CalcResponse, YearHit } from '../response/calculation';
import { numberFormat } from '../utils/template';
import { MarginService, Margin } from '../services/margin.service';
import { AccessoryCustomization } from '../price-customizations/accessory-customization';
import { AccessoryService, Accessory } from '../services/accessory.service';

interface NamedPrice {
    name: string;
    price: number;
}

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.css']
})
export class PriceTableComponent {
    @Input() currentYear: number;

    userPrices: NamedPrice[];
    fmv: number;
    visible = true;

    private _calcData: CalcResponse;

    constructor(
        private priceAdjustments: PriceCustomizationService,
        private margins: MarginService,
        private accessories: AccessoryService
    ) {
        this.userPrices = [];

        this.margins.dataChanged.subscribe((data: Margin[]) => {
            this.prepareUserPrices(data);

            this.visible = data.length > 0;
        });
    }

    @Input()
    set calcData(calcData: CalcResponse) {
        this._calcData = calcData;

        if (this._calcData) {
            let yearHit: YearHit = null;
            if (calcData.hasOwnProperty('yearHit') && calcData.yearHit.status) {
                yearHit = calcData.yearHit;
            } else if (calcData.hasOwnProperty('hit')) {
                yearHit = calcData.hit.filter((item: YearHit) => item.age === (new Date()).getFullYear() - this.currentYear).pop();
            }

            if (!yearHit) {
                this.visible = false;
            } else {
                this.fmv = this.priceAdjustments.calculate(yearHit.fmv);
                this.prepareUserPrices(this.margins.load());
            }

            if (this.margins.load().length === 0) {
                this.visible = false;
            }
        }
    }

    private prepareUserPrices(margins: Margin[]): void {
        if (margins.length > 0) {
            this.userPrices = margins.map((item: Margin) => {
                return {
                    name: `${item.name} (${item.amount}%)`,
                    price: this.fmv * (1 + (item.amount / 100))
                };
            });
        }
    }

    numberFormat(nr: number): string {
        return numberFormat(nr);
    }
}
