import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryAggregationsResponse, CountrySource } from '../response/elastic';
import { LanguageService } from '../services/language.service';
import { countries, Country } from '../utils/countries';

@Component({
    selector: 'country-select',
    template: `
        <mat-form-field *ngIf="countryAggs">
            <mat-select name="country"
                i18n-placeholder="Country placeholder@@country-placeholder"
                placeholder="Country" [(ngModel)]="current" (selectionChange)="onChange($event.value)" multiple>
                <mat-option [value]="" i18n="All countries option@@country-all-placeholder">All</mat-option>
                <mat-option *ngFor="let bucket of this.countries" [value]="country(bucket).id">
                    {{country(bucket).name[language.locale]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    `,
    styleUrls: ['./country-select.component.css']
})
export class CountrySelectComponent {
    private _countryAggs: CountryAggregationsResponse;
    countries: CountrySource[];
    @Input() current: Array<string>;
    @Output() changed = new EventEmitter<Array<string>>();

    @Input()
    set countryAggs(aggs: CountryAggregationsResponse) {
        if (aggs) {
            this._countryAggs = aggs;
            this.countries = this._countryAggs.aggregations.countries.buckets
                .filter((item: CountrySource) => item.doc_count > 60)
                .sort((a: CountrySource, b: CountrySource) =>
                      this.country(a).name[this.language.locale].localeCompare(this.country(b).name[this.language.locale]));
        }
    }

    get countryAggs(): CountryAggregationsResponse {
        return this._countryAggs;
    }

    country(bucket: CountrySource): Country {
        return countries[bucket.key];
    }

    constructor(
        public language: LanguageService
    ) {  }

    onChange(country: Array<string>) {
        if (country.indexOf(undefined) !== -1) {
            country = [];
        }
        this.changed.emit(country);
    }
}
