import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LecturaModelSourceHit } from '../response/elastic';
import { ModelProvider } from '../providers/model.provider';

@Component({
  selector: 'model-card',
  templateUrl: './model-card.component.html',
  styleUrls: ['./model-card.component.css']
})
export class ModelCardComponent {
    @Input() model: LecturaModelSourceHit;

    constructor(private router: Router, private modelProvider: ModelProvider) { }

    onClick(model: LecturaModelSourceHit) {
        this.modelProvider.current = this.model;
        console.log(this.modelProvider);
        this.router.navigate([`/search/${model.manufacturer}/${model.name}`]);
    }

    modelImage(image: string) {
        let src = 'https://www.lectura-specs.com/assets/frontend/dist/images/icons/placeholder_d.png';

        if (image) {
            src = 'https://specs.lectura.de/models/renamed/list_desktop_retina/' + image;
        }

        return src;
    }

    years(model: LecturaModelSourceHit) {
        const first = model.first_year ? model.first_year : 1990,
            last = model.last_year ? model.last_year : (new Date()).getFullYear(),
            result = [];

        if (model.first_year) {
            for (let i = first; i <= last; i++) {
                result.push(i);
            }
        }

        return result;
    }

}
