import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    LecturaModelResponse,
    CategoryAggregationsResponse,
    YearAggregationsResponse,
    CountryAggregationsResponse,
    ModelAggregationsResponse
} from '../response/elastic';
import { CalcRequest } from '../response/calculation';

@Injectable({
  providedIn: 'root'
})
export class ElasticService {
    private url = 'https://es.lectura-dev.com';

    constructor(private http: HttpClient) { }

    getModelOptions(make: string, name: string, year: number): Observable<LecturaModelResponse> {
        const formatedName = this.getFormatedName(name),
            base = this.getBaseNamePart(formatedName),
            options = {
                size: 100,
                query: {
                    bool: {
                        must: [
                            {
                                match_phrase: {
                                    manufacturer: make.toLowerCase()
                                }
                            },
                            {
                                match: {
                                    query_name: base.toLowerCase()
                                }
                            }
                        ],
                        should: [
                            {
                                match: {
                                    query_name: {
                                        query: formatedName.replace(base, '').trim(),
                                        boost: 3
                                    }
                                }
                            }
                        ]
                    }
                }
            };

        if (!name) {
            delete options.query.bool.should;
            options.query.bool.must.pop();
        }

        return this.http.post<LecturaModelResponse>(`${this.url}/models/webservice/_search`, options);
    }

    getExactModelMatch(query: string): Observable<LecturaModelResponse> {
        const options = {
            size: 100,
            sort: {
                'query_field.keyword': 'asc'
            },
            query: {
                match_phrase: {
                    query_field: query.toLowerCase()
                }
            }
        };

        return this.http.post<LecturaModelResponse>(`${this.url}/models/webservice/_search`, options);
    }

    getCategoryAggregations(query: string): Observable<CategoryAggregationsResponse> {
        const options = {
            size: 0,
            query: {
                match: {
                    autocomplete: {
                        query: query.toLowerCase(),
                        operator: 'and'
                    }
                }
            },
            aggs: {
                categories: {
                    terms: {
                        field: 'category_name.en.keyword'
                    }
                }
            }
        };

        return this.http.post<CategoryAggregationsResponse>(`${this.url}/models/webservice/_search`, options);
    }

    getLecturaModels(query: string): Observable<LecturaModelResponse> {
        const options = {
            size: 100,
            sort: {
                'query_field.keyword': 'asc'
            },
            query: {
                match: {
                    autocomplete: {
                        query: query.toLowerCase(),
                        operator: 'and'
                    }
                }
            }
        };

        return this.http.post<LecturaModelResponse>(`${this.url}/models/webservice/_search?scroll=5m`, options);
    }

    getModelsForCategory(query: string, category: string): Observable<LecturaModelResponse> {
        const options = {
            size: 100,
            sort: {
                'query_field.keyword': 'asc'
            },
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                autocomplete: {
                                    query: query.toLowerCase(),
                                    operator: 'and'
                                }
                            }
                        },
                        {
                            match_phrase: {
                                'category_name.en.keyword': category
                            }
                        }
                    ]
                }

            }
        };

        return this.http.post<LecturaModelResponse>(`${this.url}/models/webservice/_search`, options);
    }

    getListingsYearCount(manufacturer: string, model: string): Observable<YearAggregationsResponse> {
        const currentYear = new Date().getFullYear();
        const options = this.getBaseListingQuery(manufacturer, model);

        options.aggs = {
            'years': {
                'terms': {
                    'field': 'found_on_year',
                    'order': {
                        '_term': 'asc'
                    }
                }
            }
        };

        options.query.bool.must.push({
            'range': {
                'found_on_year': {
                    'gte': currentYear - 3,
                    'lte': currentYear
                }
            }
        });

        return this.http.post<YearAggregationsResponse>(`${this.url}/lectura/listings_dealer_full_v1/_search`, options);
    }

    getListingsCountryCount(request: CalcRequest): Observable<CountryAggregationsResponse> {
        const options = this.getBaseListingQuery(request.manufacturer, request.model);

        options.aggs = {
            'countries': {
                'terms': {
                    'field': 'country.keyword',
                    'size': '1000'
                }
            }
        };

        return this.http.post<CountryAggregationsResponse>(`${this.url}/lectura/listings_dealer_full_v1/_search`, options);
    }

    getListingModelAggs(request: CalcRequest): Observable<ModelAggregationsResponse> {
        const options = this.getBaseListingQuery(request.manufacturer, request.model),
            formatedName = this.getFormatedNameListings(request.model);

        options.query.bool.must[1].match_phrase.name = formatedName;
        options.aggs = {
            'models': {
                'terms': {
                    'field': 'name.keyword',
                    'size': 100
                }
            }
        };

        if (request.country.length > 0) {
            options.query.bool.filter = {
                'terms': {
                    'country': request.country.map((item: string) => item.toLowerCase())
                }
            };
        }

        return this.http.post<ModelAggregationsResponse>(`${this.url}/lectura/listings_dealer_full_v1/_search`, options);
    }

    private getBaseListingQuery(manufacturer: string, name: string, year?: number, country?: string): any {
        const formatedName = this.getFormatedName(name),
            base = this.getBaseNamePart(formatedName),
            currentYear = new Date().getFullYear();
        const options = {
            'size': 0,
            'query': {
                'bool': {
                    'must': [
                        {
                            'match_phrase': {
                                'manufacturer': manufacturer
                            }
                        },
                        {
                            'match_phrase': {
                                'name': formatedName
                            }
                        },
                        {
                            'range': {
                                'price': {
                                    'gte': 2000
                                }
                            }
                        },
                        {
                            'range': {
                                'year': {
                                    'gte': 1990,
                                    'lte': currentYear + 1
                                }
                            }
                        },
                        {
                            'match_phrase': {
                                'currency': 'EUR'
                            }
                        }
                    ]
                }
            },
            'aggs': {

            }
        };

        return options;
    }

    getBaseNamePart(name: string): string {
        const nameParts = name.split(' ');
        let base = '',
            numeric = false;

        for (const part of nameParts) {
            base += part + '  ';

            if (numeric === true) {
                break;
            }

            if (!isNaN(parseInt(part, 10))) {
                numeric = true;
            }
        }

        return base.trim();
    }

    getFormatedName(name: string): string {
        return name.replace(/[^0-9a-zA-Z .]/, ' ')
            .replace(/(\d+)/g, ' $1 ')
            .replace(/\s+/, ' ')
            .trim();
    }

    getFormatedNameListings(name: string): string {
        return name.replace(/[^0-9a-zA-Z .]/, ' ')
            .replace(/([1-9]\d*\.\d+?|[0-9a-zA-Z]\d*\-\d+?|\d+)/g, ' $1 ')
            .replace(/\s+/, ' ')
            .trim();
    }
}
