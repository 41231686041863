import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
        <div class="mat-typography">
            <h3 i18n="FMV tooltip heading@@fmv-tooltip-heading">What is this number?</h3>
            <ul>
                <li i18n="FMV tooltip text@@fmv-tooltip-text">
                    The indication shows the <span class="mat-primary">most probable fair market price range (FMPR)</span>
                    based on observations of the LECTURA engine.
                </li>
            </ul>
            <button style="float:right" mat-raised-button (click)="onClick()" i18n="Tooltip close button@@tooltip-close">Close</button>
            <div style="clear:both"></div>
        </div>
    `,
})
export class FMVTooltip {

  constructor(public dialogRef: MatDialogRef<FMVTooltip>) {}

  onClick(): void {
    this.dialogRef.close();
  }

}
