import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { Accessory, AccessoryService } from '../services/accessory.service';
import { PriceCustomizationService } from '../services/price-customization.service';

@Component({
  selector: 'app-accessory-popup',
  templateUrl: './accessory-popup.component.html',
  styleUrls: ['./accessory-popup.component.css']
})
export class AccessoryPopupComponent {

  constructor(public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(AccessoryDialogComponent, {width: '500px'});
  }
}

@Component({
  selector: 'dialog-accessory',
  templateUrl: './dialog-accessory.html'
})
export class AccessoryDialogComponent {
  displayedColumns = ['inUse', 'name', 'price', 'influence', 'buttons'];
  dataSource: MatTableDataSource<Accessory> = new MatTableDataSource<Accessory>();

  constructor(
    public dialogRef: MatDialogRef<AccessoryDialogComponent>,
    private service: AccessoryService,
    private customizations: PriceCustomizationService
  ) {
    this.dataSource.data = this.service.load() || [];
  }

  save() {
    this.removeEmpty();
    this.service.save(this.dataSource.data);
    this.addEmpty();
  }

  delete(accessory: Accessory) {
    const index = this.dataSource.data.indexOf(accessory);

    this.dataSource.data.splice(index, 1);

    if (this.dataSource.data.length === 0) {
      this.addEmpty();
    } else {
      this.save();
    }
  }

  addEmpty() {
    const data = this.dataSource.data;
    data.push({name: '', price: null, influence: null, inUse: false});
    this.dataSource.data = data;
  }

  private removeEmpty() {
    this.dataSource.data = this.dataSource.data.filter(
      (item: Accessory) => item.name !== '' && item.price !== null && item.influence !== null);
  }
}
