import { Component, OnDestroy } from '@angular/core';
import { CalcRequest } from '../response/calculation';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SearchService } from '../services/search.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnDestroy {
    router: Router;
    searchService: SearchService;
    request: CalcRequest = new CalcRequest();
    maxYear = (new Date()).getFullYear();
    keyUp = new Subject<KeyboardEvent>();
    subscription: Subscription;
    isHandset = false;
    breakpointObserver: BreakpointObserver;

    constructor(
        router: Router,
        searchService: SearchService,
        breakpointObserver: BreakpointObserver
    ) {
        this.router = router;
        this.searchService = searchService;
        this.request.year = (new Date()).getFullYear() - 3;

        breakpointObserver.observe(['(max-width: 819px)']).subscribe(result => {
            this.isHandset = result.matches;
        });

        this.subscription = this.keyUp.pipe(
            debounceTime(150),
            distinctUntilChanged()
        ).subscribe((event: Event) => {
            this.request.manufacturer = this.request.manufacturer ? this.request.manufacturer : '';
            this.request.model = this.request.model ? this.request.model : '';

            if (!this.isHandset) {
                this.searchService.toggleHeaderForm(this.request);
                this.searchService.run(this.request);
            }
        });
    }

    onSubmit() {
        this.request.country = [];
        this.request.batchModels = [];
        this.request.usage = null;
        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
