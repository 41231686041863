import { Component, OnInit, ViewChild, AfterViewChecked, OnDestroy } from '@angular/core';
import { ModelProvider } from '../providers/model.provider';
import {
    LecturaModelSourceHit,
    LecturaModelResponse,
    Translation,
    CountryAggregationsResponse,
    ModelAggregationsResponse } from '../response/elastic';
import { ActivatedRoute, Router } from '@angular/router';
import { ElasticService } from '../services/elastic.service';
import { CalculationService } from '../services/calculation.service';
import { forkJoin, Subscription } from 'rxjs';
import { CalcResponse, CalcRequest } from '../response/calculation';
import { Params } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { LocalStorageService } from '../services/localStorage.service';
import { MenuService } from '../services/menu.service';
import { MatSidenav, MatDialog, MatDialogRef } from '@angular/material';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { prepareQuery } from '../utils/query';

declare global {
    interface Window { dataLayer: any; }
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav') public sidenav: MatSidenav;
    request = new CalcRequest();
    model: LecturaModelSourceHit;
    calcData: CalcResponse;
    loading: boolean;
    countryAggs: CountryAggregationsResponse;
    modelAggs: ModelAggregationsResponse;
    menuSubscription: Subscription;
    menuOpened = true;
    menuMode = 'push';
    isHandset = false;
    cols = 2;
    showSpecificationLink = false;

    constructor(
        private modelProvider: ModelProvider,
        private route: ActivatedRoute,
        private els: ElasticService,
        private cls: CalculationService,
        private router: Router,
        private viewportScroll: ViewportScroller,
        private storage: LocalStorageService,
        private menuService: MenuService,
        private breakpointObserver: BreakpointObserver,
        public dialog: MatDialog
    ) {
        this.loading = true;
        this.menuSubscription = this.menuService.missionToggled.subscribe(dummy => {
            this.menuOpened = !this.menuOpened;
        });

        this.breakpointObserver.observe(['(max-width: 819px)']).subscribe(result => {
            this.isHandset = result.matches;

            if (!this.isHandset) {
                this.menuOpened = true;
                this.menuMode = 'side';
                this.cols = 2;
            } else {
                this.menuOpened = false;
                this.menuMode = 'push';
                this.cols = 1;
            }
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => this.request = CalcRequest.fromParams(params));
        this.loadData();
    }

    loadData() {
        this.route.queryParams.subscribe(params => {
            this.loading = true;
            forkJoin(
                this.els.getListingsCountryCount(this.request),
                this.els.getListingModelAggs(this.request),
                this.cls.getData(this.request),
                this.els.getLecturaModels(prepareQuery(`${this.request.manufacturer} ${this.request.model}`))
            ).subscribe(([countryResponse, modelResponse, clsResponse, modelsResponse]) => {
                this.modelAggs = modelResponse;
                this.countryAggs = countryResponse;
                this.calcData = clsResponse;
                this.loading = false;
                this.request.apiKey = '';
                this.request.response = '';
                this.calcData = clsResponse;
                this.modelProvider.current = this.model = this.emptyModel();

                this.showSpecificationLink = modelsResponse.hits.total > 0;

                if (this.calcData.status) {
                    this.storage.set('previous', this.request);
                }
            });
        });
    }

    onPreviousClick(event: Event) {
        event.preventDefault();
        let request = this.storage.get('previous');

        if (!request) {
            this.router.navigateByUrl('/');
        } else {
            request = CalcRequest.fromParams(request);
            this.router.navigateByUrl(`/search?${request.toString()}`);
        }
    }

    scrollToAnchor(fragment: string) {
        window.location.hash = '';
        window.location.hash = fragment;
        this.viewportScroll.scrollToAnchor(fragment);
        /*
        console.log(this.viewportScroll);
        this.viewportScroll.scrollToAnchor(fragment);
        */
    }

    private emptyModel() {
        const t = new Translation('', '', '', '', '', '', '');
        return new LecturaModelSourceHit(null, this.request.model, this.request.manufacturer, '', 0, 0, 0, '', '', t, t, '', 0, 0);
    }

    comingSoon() {
        const dialogRef = this.dialog.open(ComingSoonDialogComponent, {width: '500px'});
    }

    ngOnDestroy() {
        this.menuSubscription.unsubscribe();
    }
}

@Component({
  selector: 'dialog-coming-soon',
  template: `
    <div class="mat-typography" style="text-align: center">
        <h1>Coming soon</h1>
        <mat-icon style="color: #ff9800; height: 40px; width: 40px; font-size: 40px">whatshot</mat-icon>
    </div>
  `
})
export class ComingSoonDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ComingSoonDialogComponent>,
    ) {}
}
