import { Component, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { LecturaModelSourceHit } from '../response/elastic';
import { CalcResponse, CalcRequest } from '../response/calculation';
import { numberFormat, proportion } from '../utils/template';
import { PriceCustomizationService } from '../services/price-customization.service';
import { MarginService, Margin } from '../services/margin.service';
import { AccessoryService, Accessory } from '../services/accessory.service';
import { FMVService } from '../services/fmv.service';
import { SeriesOptions } from 'highcharts';
import { GraphTooltipService } from '../services/graph-tooltip.service';
import { LanguageService } from '../services/language.service';

interface Serie {
    name: string;
    data: [number, number][];
    type: string;
    color: string;
}

@Component({
  selector: 'forecast-graph',
  templateUrl: './forecast-graph.component.html',
  styleUrls: ['./forecast-graph.component.css']
})
export class ForecastGraphComponent {
    chart: Chart;
    @Input() data: CalcResponse;
    @Input() request: CalcRequest;
    private _model: LecturaModelSourceHit;
    private adjustableSeriesStart = 3;
    private marginColors = ['#04ff00', '#05d102', '#78c976', '#167f13', '#045b01', '#7f00ff', '#7926d3', '#865bb7', '#492177', '#260154'];

    constructor(
        private customizations: PriceCustomizationService,
        private margins: MarginService,
        private accessories: AccessoryService,
        private fmvService: FMVService,
        private graphService: GraphTooltipService,
        private language: LanguageService
    ) {
        this.fmvService.fmvChanged.subscribe(() => {
            // todo: check for proper functionality - add/remove ci series, proper margin delete
            while (this.adjustableSeriesStart < this.chart.ref.series.length) {
                this.chart.removeSerie(this.chart.ref.series.length - 1);
            }

            this.chart.ref.series[2].setData(this.makeFmvSeriesData());

            if (!this.customizations.isActive() && this.margins.load().length === 0) {
                for (const s of this.makeCISeries()) {
                    this.chart.addSerie(s);
                }
            }

            for (const s of this.makeMarginSeries()) {
                this.chart.addSerie(s);
            }

            this.chart.addSerie(this.fmvRangeSeries());
        });
    }

    @Input()
    set model(model: LecturaModelSourceHit) {
        this._model = model;
        if (this._model) {
            this.createChart();
        }
    }

    get model(): LecturaModelSourceHit {
        return this._model;
    }

    formatPrice(price: number) {
        return Math.round(price);
    }

    createChart() {
        const self = this;
        this.graphService.setData(this.data);
        this.chart = new Chart({
            tooltip: {
                enabled: true,
                shared: true,
                useHTML: true,
                formatter: function() {
                    const listSeries = (!self.customizations.isActive() && self.margins.load().length === 0)
                        ? self.range(3, this.points.length - 1)
                        : self.range(1, this.points.length - 1),
                        result = `
                        <div class="graph__labels graph__labels--top">
                            <div class="graph__label graph__label--left">${numberFormat(self.graphService.getLowCI(this.x))} €</div>
                            <div class="graph__label graph__label--median"></div>
                            <div class="graph__label graph__label--right">
                                ${numberFormat(self.graphService.getHighCI(this.x))} €
                            </div>
                        </div>
                        <div class="calculation-graph__top-line">
                            <div class="calculation-graph__mid-median"
                                style="left:
                                    ${proportion(self.graphService.getLowCI(this.x)
                                      + self.graphService.getHighCI(this.x), self.graphService.getFMV(this.x))}%">|</div>
                        </div>
                        <div class="graph__labels graph__labels--bottom">
                            <div class="graph__label graph__label--median"
                                style="left: calc(${proportion(
                                    self.graphService.getLowCI(this.x) + self.graphService.getHighCI(this.x),
                                    self.graphService.getFMV(this.x))}% - 15px)">
                                    ${numberFormat(self.graphService.getFMV(this.x))} €
                            </div>
                        </div>
                        <div class="graph__tooltip" style="display: ${listSeries.length > 0 ? 'block' : 'none'}">
                            <ul>
                            ${listSeries.map((index: number): string => {
                                return `<li>
                                    <strong class="line" style="color: ${this.points[index].color}">-</strong>
                                    ${this.points[index].series.name}: <strong>${numberFormat(this.points[index].y)} €</strong>
                                </li>`;
                            }).reduce((acc: string, current: string) => acc + current, '')}
                            </ul>
                        </div>
                    `;

                    return result;
                }
            },
            chart: {
                backgroundColor: '#fafafa'
            },
            title: {
                text: ''
            },
            xAxis: {
                tickInterval: 1,
                title: {
                    text: 'Age in years'
                }
            },
            yAxis: {
                title: {
                    text: 'Price in €'
                }
            },
            series: this.getSeries()
        });
    }

    private getSeries(): Object[] {
        const series: SeriesOptions[] = [
            {
                type: 'scatter',
                name: this.language.locale === 'en' ? 'Excluded observations' : 'Ausgeschlossene Datenpunkte',
                cropThreshold: 99999,
                color: '#dedede',
                visible: false,
                enableMouseTracking: false,
                data: this.getAllPoints()
            },
            {
                type: 'scatter',
                name: this.language.locale === 'en' ? 'Included observations' : 'Eingeschlossene Datenpunkte',
                cropThreshold: 99999,
                enableMouseTracking: false,
                color: '#8c8c8c',
                data: this.getIncludedPoints()
            },
            {
                type: 'line',
                name: this.language.locale === 'en' ? 'FMV depreciation' : 'FMV Indikation',
                color: '#fb9803',
                data: this.makeFmvSeriesData()
            }
        ];

        if (!this.customizations.isActive() && this.margins.load().length === 0) {
            for (const s of this.makeCISeries()) {
                series.push(s);
            }
        }

        if (this.margins.load().length > 0) {
            for (const s of this.makeMarginSeries()) {
                series.push(s);
            }
        }

        series.push(this.fmvRangeSeries());
        return series;
    }

    private makeCISeries(): SeriesOptions[] {
        const series = [];

        series.push({
            type: 'line',
            name: 'CI -2.5%',
            data: this.makeSeriesFor('lowCI'),
            color: '#28b2c4',
            dashStyle: 'LongDash'
        });
        series.push({
            type: 'line',
            name: 'CI +2.5%',
            data: this.makeSeriesFor('highCI'),
            color: '#28b2c4',
            dashStyle: 'LongDash'
        });

        return series;
    }

    private fmvRangeSeries(): SeriesOptions {
        return {
            type: '',
            name: 'FMV range',
            color: '#cc00ff',
            lineWidth: 12,
            data: [
                [this.request.age, this.fmvService.getFMVRange(this.data, this.request.year).low],
                [this.request.age, this.fmvService.getFMVRange(this.data, this.request.year).high]
            ]
        };
    }

    private makeMarginSeries(): Serie[] {
        const userPriceSeries = this.makeUserPriceSeries(), series = [], margins = this.margins.load();
        let i = 0;
        for (const margin of margins) {
            const s = {
                type: 'line',
                color: this.marginColors[i % 10],
                name: `${margin.name} (${margin.amount}%)`,
                data: []
            };

            for (const point of userPriceSeries) {
                s.data.push([point[0], point[1] * (1 + margin.amount / 100)]);
            }

            series.push(s);
            i++;
        }

        return series;
    }

    private makeUserPriceSeries(): [number, number][] {
        const serie  = this.makeSeriesFor('fmv');

        for (const i in serie) {
            if (serie.hasOwnProperty(i)) {
                serie[i][1] = this.customizations.calculate(serie[i][1]);
            }
        }

        return serie;
    }

    private makeSeriesFor(type: string): [number, number][] {
        const r = [];
        for (const i in this.data.hit) {
            if (this.data.hit.hasOwnProperty(i)) {
                r.push([this.data.hit[i].age, this.data.hit[i][type]]);
            }
        }

        return r;
    }

    private makeFmvSeriesData(): [number, number][] {
        const r = [];
        for (const i in this.data.hit) {
            if (this.data.hit.hasOwnProperty(i)) {
                r.push([this.data.hit[i].age, this.fmvService.getFMV(this.data, this.data.hit[i].age)]);
            }
        }

        return r;
    }

    private getAllPoints(): [number, number][] {
        const r = [];
        for (const i in this.data.data) {
            if (this.data.hit.hasOwnProperty(i)) {
                r.push([this.data.data[i].age_in_months / 12, parseFloat(this.data.data[i].price)]);
            }
        }

        return r;
    }

    private getIncludedPoints(): [number, number][] {
        const r = [];
        for (const i in this.data.data) {
            if (this.data.unused.indexOf(this.data.data[i].id) === -1) {
                r.push([this.data.data[i].age_in_months / 12, parseFloat(this.data.data[i].price)]);
            }
        }

        return r;
    }

    private range(start: number, end: number) {
        if (end - start + 1 > 0) {
            return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
        } else {
            return [];
        }
    }
}
