import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
        <div class="mat-typography">
            <h3 i18n="Model options tooltip heading@@tooltip-model-options-heading">Enclose your search and receive more specific results</h3>
            <ul>
                <li i18n="Model options tooltip first bullet@@tooltip-model-options-first">
                    Select/deselect <strong>machine variants</strong> in the section below.
                </li>
                <li i18n="Model options tooltip second bullet@@tooltip-model-options-second">
                    Enclose the FMPR by selecting <strong>filter options</strong> to your right: Specify by
                    <strong>age of the machine</strong> in years, <strong>working hour</strong>
                    dependancy and trading <strong>country</strong>.
                </li>
            </ul>
            <button style="float:right" mat-raised-button (click)="onClick()" i18n="Tooltip close button@@tooltip-close">Close</button>
            <div style="clear:both"></div>
        </div>
    `,
})
export class ModelOptionsTooltip {

  constructor(public dialogRef: MatDialogRef<ModelOptionsTooltip>) {}

  onClick(): void {
    this.dialogRef.close();
  }

}
