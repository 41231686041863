import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LecturaModelSource, LecturaModelResponse } from '../response/elastic';
import { ElasticService } from '../services/elastic.service';
import { CalcRequest } from '../response/calculation';
import { modelImage, yearsText, classonText } from '../utils/template';
import { prepareQuery } from '../utils/query';
import { MatPaginator } from '@angular/material/paginator';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-specs-box',
  templateUrl: './specs-box.component.html',
  styleUrls: ['./specs-box.component.css']
})
export class SpecsBoxComponent implements OnInit {
    private _req: CalcRequest;
    displayedColumns: string[] = ['image', 'category', 'manufacturer', 'name',  'extra', 'years', 'classon', 'button'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<LecturaModelSource> = new MatTableDataSource<LecturaModelSource>();

    constructor(
        private els: ElasticService,
        public language: LanguageService
    ) { }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
    }

    @Input()
    set request(req: CalcRequest) {
        this._req = req;
        if (this._req) {
            this.els.getLecturaModels(prepareQuery(`${req.manufacturer} ${req.model}`)).subscribe((res: LecturaModelResponse) => {
                this.dataSource.data = res.hits.hits;
            });
        }
    }

    imageUrl(image: string) {
        return modelImage(image);
    }

    years(model: LecturaModelSource): string {
        return yearsText(model._source.first_year, model._source.last_year);
    }

    classon(model: LecturaModelSource, locale: string): string {
        return classonText(model, locale);
    }
}
