import { Component, Input } from '@angular/core';
import { LecturaModelSourceHit, ModelSource, ModelAggregationsHit, ModelAggregationsResponse } from '../response/elastic';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ElasticService } from '../services/elastic.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CalcRequest, CalcResponse } from '../response/calculation';
import { Router } from '@angular/router';
import { ListingCollection, ModelAggSource } from '../utils/ListingCollection';

@Component({
  selector: 'model-options',
  templateUrl: './model-options.component.html',
  styleUrls: ['./model-options.component.css']
})
export class ModelOptionsComponent {
    displayedColumns: string[] = ['selection', 'name', 'observations'];
    private _model: LecturaModelSourceHit;
    private _coll: ListingCollection;
    private _modelAggs: ModelAggregationsResponse;
    @Input() request: CalcRequest;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<ModelAggSource>;
    selection = new SelectionModel<ModelAggSource>(true, []);

    constructor(private els: ElasticService, private router: Router) { }

    @Input()
    set modelAggs(aggs: ModelAggregationsResponse) {
        if (aggs) {
            this._modelAggs = aggs;
            this.dataSource = new MatTableDataSource(
                this._modelAggs.aggregations.models.buckets
                    .filter((item: ModelSource) => item.doc_count >= 10)
                    .map((item: ModelSource) => {
                        return {name: item.key, observations: item.doc_count};
                    })
            );
            this.dataSource.paginator = this.paginator;

            if (this.request.batchModels.length > 0) {
                this.toggleBatch();
            } else {
                this.toggleAll();
            }
        }
    }

    @Input()
    set model(model: LecturaModelSourceHit) {
        if (model) {
            this._model = model;
        }
    }

    get model(): LecturaModelSourceHit {
        return this._model;
    }

    toggleAll(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        if (this.selection.selected.length === this.dataSource.data.length) {
            this.selection = new SelectionModel<ModelAggSource>(true, []);
        } else {
            this.dataSource.data.map((item: ModelAggSource) => this.selection.select(item));
        }
    }

    toggleBatch() {
        this.dataSource.data
            .filter((item: ModelAggSource) => this.request.batchModels.indexOf(item.name) !== -1)
            .map((item: ModelAggSource) => this.selection.select(item));
    }

    useVariants(event: Event) {
        event.preventDefault();

        if (this.selection.selected.length !== this.dataSource.data.length) {
            this.request.batchModels = this.selection.selected.map((item: ModelAggSource) => item.name);
        } else {
            this.request.batchModels = [];
        }

        this.router.navigateByUrl(`/search?${this.request.toString()}`);
    }
}
