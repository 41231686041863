import { Component, LOCALE_ID, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) {
        if (this.locale === 'en-US') {
            this.locale = 'en';
        }
    }
}
