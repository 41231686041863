import { Component, ViewChild, OnInit } from '@angular/core';
import { LecturaModelSourceHit } from '../response/elastic';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ElasticService } from '../services/elastic.service';
import { Input } from '@angular/core';
import { CalcResponse, ListingData } from '../response/calculation';
import { numberFormat } from '../utils/template';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'listings-table',
  templateUrl: './listings-table.component.html',
  styleUrls: ['./listings-table.component.css']
})
export class ListingsTableComponent implements OnInit {
    displayedColumns: string[] = ['name', 'manufacturer', 'age_in_months', 'year', 'country', 'price', 'operating_hours', 'buttons'];
    @Input() model: LecturaModelSourceHit;
    private _calcData: CalcResponse;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<ListingData> = new MatTableDataSource<ListingData>();

    constructor(
        private els: ElasticService
    ) { }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    @Input()
    set calcData(calcData: CalcResponse) {
        if (calcData) {
            this._calcData = calcData;
            this.dataSource.data = this._calcData.data;
        }
    }

    get calcData(): CalcResponse {
        return this._calcData;
    }

    isUsedRow(item: ListingData) {
        return this._calcData.unused.indexOf(item.id) !== -1 ? 'not-used' : '';
    }

    format(price: any): string {
        return numberFormat(parseFloat(price));
    }

    formatAge(age: string): string {
        return parseFloat(age).toFixed(0);
    }

    observationsCount() {
        return this._calcData.hasOwnProperty('backgrounds') ? this._calcData.backgrounds.observation : this._calcData.data.length;
    }
}
