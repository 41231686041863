import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Margin, MarginService } from '../services/margin.service';

@Component({
  selector: 'app-margin-popup',
  templateUrl: './margin-popup.component.html',
  styleUrls: ['./margin-popup.component.css']
})
export class MarginPopupComponent {

  constructor(public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(MarginDialogComponent, {width: '600px'});
  }

}

@Component({
  selector: 'dialog-margin',
  templateUrl: './dialog-margin.html'
})
export class MarginDialogComponent {
  margins: Margin[];

  constructor(
    public dialogRef: MatDialogRef<MarginDialogComponent>,
    private service: MarginService
  ) {
    const savedMargins = this.service.load();
    this.margins = [];

    if (savedMargins) {
      this.margins = savedMargins;
    }

    this.addEmpty();
  }

  addEmpty() {
    this.margins.push({name: '', amount: null});
  }

  save() {
    this.removeEmpty();
    this.service.save(this.margins);
    this.addEmpty();
  }

  delete(m: Margin) {
    const index = this.margins.indexOf(m);

    this.margins.splice(index, 1);

    if (this.margins.length === 0) {
      this.addEmpty();
    } else {
      this.save();
    }
  }

  private removeEmpty() {
    this.margins = this.margins.filter((item: Margin) => item.name !== '' && item.amount !== null);
  }
}
