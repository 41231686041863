import { Component, Input } from '@angular/core';
import { Chart, MapChart } from 'angular-highcharts';
import { LecturaModelSourceHit, CountryAggregationsResponse, CountrySource } from '../response/elastic';
import { ElasticService } from '../services/elastic.service';
import { ListingCollection } from '../utils/ListingCollection';
import { CalcResponse } from '../response/calculation';
import { mapData } from '../utils/worldMapData';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'country-graph',
  templateUrl: './country-graph.component.html',
  styleUrls: ['./country-graph.component.css']
})
export class CountryGraphComponent {
    chart: Chart;
    mapChart: MapChart;
    colSpan = 1;
    cols = 1;
    @Input() model: LecturaModelSourceHit;

    private _calcData: CalcResponse;
    private _countrySeriesData: [string, number][];
    private _mapSeriesData: Object[];
    private _coll: ListingCollection;

    constructor(private els: ElasticService, private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver.observe(['(max-width: 819px)']).subscribe(result => {
            if (result.matches) {
                this.colSpan = 1;
                this.cols = 1;
            } else {
                this.colSpan = 2;
                this.cols = 3;
            }
        });
    }

    @Input()
    set calcData(calcData: CalcResponse) {
        if (calcData) {
            this._calcData = calcData;
            this._coll = new ListingCollection(this._calcData.data, this._calcData.unused);
            this._countrySeriesData = [];
            this._mapSeriesData = [];
            const countryData = this._coll.countryCounts(),
                keys = Object.keys(countryData);
            for (const iso of keys) {
                this._countrySeriesData.push([iso, countryData[iso]]);
                this._mapSeriesData.push({code: iso, value: countryData[iso]});
            }

            this.createChart();
            this.createMap();
        }
    }

    get calcData(): CalcResponse {
        return this._calcData;
    }


    createChart() {
        this.chart = new Chart({
            chart: {
                backgroundColor: '#fafafa',
                width: 350,
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45
                }
            },
            title: {
                text: ''
            },
            plotOptions: {
                pie: {
                    innerSize: 100,
                    depth: 45
                }
            },
            series: [{
                name: 'Countries',
                data: this._countrySeriesData
            }]
        });
    }

    createMap() {
        this.mapChart = new MapChart({
            chart: {
                map: 'custom/world',
                backgroundColor: '#fafafa'
            },
            title: {
                text: ''
            },
            mapNavigation: {
                enabled: true,
                enableMouseWheelZoom: false,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },

            colorAxis: {
                minColor: '#fddca9',
                maxColor: '#ff9900'
            },
            series: [{
                data: this._mapSeriesData,
                mapData: mapData,
                joinBy: ['iso-a2', 'code'],
                name: 'Machine availability',
                states: {
                    hover: {
                        color: '#397eda'
                    }
                },
                dataLabels: {
                    enabled: false,
                    format: '{point.name}'
                }
              }]
        });
    }
}
