import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageService } from '../services/language.service';

/* tslint:disable */
const countries = {'NAM':['CA','US'],'EUR':['AD','AL','AT','BA','BG','BY','CH','CY','CZ','DE','DK','EE','ES','FI','FO','GI','GL','GR','HR','HU','IS','IT','LI','LT','LV','MD','ME','MK','MT','NO','PL','PT','RO','RS','RU','SE','SI','SK','SM','TR','UA','VA'],'EUW':['BE','FR','GB','IE','LU','MC','NL'],'ASI':['AE','AF','AM','AZ','BD','BH','BN','BT','CN','GE','HK','IL','IN','IQ','IR','JO','JP','KG','KH','KP','KR','KW','KZ','LA','LB','LK','MM','MN','MO','MV','MY','NP','PH','PK','QA','SA','SG','SY','TH','TJ','TM','TW','UZ','VN','YE'],'AFR':['AO','BF','BI','BJ','BW','CD','CF','CG','CI','CM','CV','DJ','DZ','EG','EH','ER','ET','GA','GH','GM','GN','GQ','GW','KE','KM','LR','LS','LY','MA','MG','ML','MR','MU','MW','MZ','NA','NE','NG','OM','RW','SD','SL','SN','SO','ST','SZ','TD','TG','TN','TO','TZ','UG','ZA','ZM','ZW'],'LAM':['AG','AI','AN','AR','AW','BB','BO','BR','BS','BZ','CL','CO','CR','CU','DM','DO','EC','GD','GF','GP','GT','GY','HN','HT','JM','KY','LC','MQ','MS','MX','NI','PA','PE','PR','PY','SR','SV','TC','TT','UY','VC','VE','VG','VI']};
/* tslint:enable */


@Component({
    selector: 'app-region-select',
    template: `
        <mat-form-field>
            <mat-select name="region"
                i18n-placeholder="Region placeholder@@region-placeholder"
                placeholder="Region" [(value)]="current" (selectionChange)="onChange($event.value)">
                <mat-option *ngFor="let region of this.regions" [value]="region.id">
                    {{region.name[language.locale]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    `,
    styleUrls: []
})
export class RegionSelectComponent {
    regions = [
        {id: 'NAM', name: {en: 'North America', de: 'Nordamerika'}},
        {id: 'EUR', name: {en: 'Europe', de: 'Europa'}},
        {id: 'EUW', name: {en: 'Western Europe', de: 'Westeuropa'}},
        {id: 'ASI', name: {en: 'Asia', de: 'Asien'}},
        {id: 'AFR', name: {en: 'Africa', de: 'Afrika'}}
    ];

    @Input() current: string;
    @Output() changed = new EventEmitter<Array<string>>();

    constructor(
        public language: LanguageService
    ) {  }

    onChange(region: string) {
        this.changed.emit(countries[region]);
    }
}
