import { Customization } from './customization';

export const CONDITIONS = {
    '4': 'Excellent',
    '3': 'Good',
    '2': 'Average',
    '1': 'Poor'
};

export const CONDITIONS_DE = {
    '4': 'Sehr gut',
    '3': 'Gut',
    '2': 'Durchshnitt',
    '1': 'Schlecht'
}

const defaultCondition = '2';
const fixedZustand = 0.32;

export class ConditionCustomization implements Customization {
    private data = defaultCondition;

    getData(): string {
        return this.data;
    }

    setData(data: string) {
        this.data = data;
    }

    calculate(price: number): number {
        return price * (1 + (fixedZustand * (parseInt(this.data, 10) - 2) / 3));
    }

    isActive(): boolean {
        return this.data && this.data !== defaultCondition;
    }
}
