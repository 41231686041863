import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';

export class CalcRequest {
    apiKey: string;
    manufacturer: string;
    model: string;
    year: number;
    country: string[];
    usage: number;
    dataYear: number;
    batchModels: string[];
    response: string;
    condition: string;
    region: string;

    static fromParams(params: Params) {
        const request = new CalcRequest();

        request.manufacturer = params.manufacturer || '';
        request.model = params.model || '';
        request.apiKey = params.apiKey || '';
        request.year = params.year || null;
        request.country = params['country[]'] || [];
        request.usage = params.usage || null;
        request.dataYear = params.dataYear || null;
        request.batchModels = params['batchModels[]'] || [];
        request.condition = params.condition || '2';
        request.region = params.region || '';

        if (!Array.isArray(request.country)) {
            request.country = [request.country];
        }

        if (!Array.isArray(request.batchModels)) {
            request.batchModels = [request.batchModels];
        }

        return request;
    }

    constructor() {
        this.response = '';
        this.apiKey = '';
        this.condition = '2';
        this.region = '';
    }

    get age(): number {
        let res = null;
        if (this.year) {
            res = (new Date()).getFullYear() - this.year;
        }

        return res;
    }

    toString() {
        let params = new HttpParams();
        if (this.model) {
            params = params.set('model', this.model);
        }
        if (this.manufacturer) {
            params = params.set('manufacturer', this.manufacturer);
        }
        if (this.year) {
            params = params.set('year', this.year.toString());
        }
        if (this.usage) {
            params = params.set('usage', this.usage.toString());
        }
        if (this.dataYear) {
            params = params.set('dataYear', this.dataYear.toString());
        }
        if (this.response) {
            params = params.set('response', this.response);
        }
        if (this.apiKey) {
            params = params.set('apiKey', this.apiKey);
        }
        if (this.condition) {
            params = params.set('condition', this.condition);
        }

        if (this.country) {
            if (!Array.isArray(this.country)) {
                this.country = [this.country];
            }

            for (const i in this.country) {
                if (this.country.hasOwnProperty(i)) {
                    params = params.append('country[]', this.country[i]);
                }
            }
        }

        if (this.batchModels) {
            if (!Array.isArray(this.batchModels)) {
                this.batchModels = [this.batchModels];
            }

            for (const i in this.batchModels) {
                if (this.batchModels.hasOwnProperty(i)) {
                    params = params.append('batchModels[]', this.batchModels[i]);
                }
            }
        }

        return params.toString();
    }
}

export enum Quality {
    Outstanding = 'outstanding',
    Solid = 'solid',
    Weak = 'weak'
}

export class Background {
    quality: Quality;
    r2: number;
    movingBy: number;
    observation: number;
}

export class YearHit {
    status: boolean;
    lowCI: number;
    highCI: number;
    fmv: number;
    median: number;
    mean: number;
    min: number;
    max: number;
    age: number;
}

export class ListingData {
    id: number;
    name: string;
    manufacturer: string;
    year: number;
    country: string;
    price: string;
    operating_hours: number;
    currency: string;
    url: string;
    duplicate_hash: string;
    serial_number: string | null;
    age_in_months: number;
    found_on_year: number;
    company: string;
}

export class HoursHit {
    age: number;
    fmv: number;
}

export class CalcResponse {
    status: boolean;
    backgrounds: Background;
    yearHit: YearHit;
    hit: YearHit[];
    unused: number[];
    data: ListingData[];
    hoursHit?: HoursHit;
}

export class ListingDescription {
    original_category: string;
    serial_number: string | null;
    region: string | null;
    zip_code: string | null;
    company: string;
    image: string;
    description: string | null;
    status: boolean;
}
