/* tslint:disable */
import { LecturaModelSourceHit, LecturaModelSource } from '../response/elastic';

export const numberFormat = function(number?: number): string {
    if (number) {
        const num = number.toFixed(Math.max(0, ~~0)),
            c = ',',
            s = '.',
            n = 0,
            x = 3,
            re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    } else {
        return '';
    }
};

export const proportion = function(a: number, b: number): number {
    return Math.abs(Math.round(100 * b / a));
};


export const modelImage = function(image: string): string {
    let src = 'https://www.lectura-specs.com/assets/frontend/dist/images/icons/placeholder_d.png';

    if (image) {
        src = 'https://specs.lectura.de/models/renamed/list_desktop_retina/' + image;
    }

    return src;
};

export const yearsText = function(firstYear: number|null, lastYear: number|null): string {
    let text = '';

    if (firstYear) {
        text =  `${firstYear ? firstYear : ''} - ${lastYear ? lastYear : 'present'}`;
    }

    return text;
};

export const classonText = function(model: LecturaModelSource, locale: string): string {
    let text = '';

    if (model._source.classon_name.en) {
        text = `${model._source.classon_name[locale]}: ${model._source.tech_data_value ? parseFloat(parseFloat(model._source.tech_data_value).toFixed(3)) : ''}${model._source.td_unit ? model._source.td_unit : ''}`;
    }

    return text;
};
