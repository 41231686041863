import { Component, Input } from '@angular/core';
import { CalcResponse, YearHit } from '../response/calculation';
import { LecturaModelSourceHit } from '../response/elastic';

@Component({
  selector: 'calculation-graph',
  templateUrl: './calculation-graph.component.html',
  styleUrls: ['./calculation-graph.component.css']
})
export class CalculationGraphComponent {
    @Input() model: LecturaModelSourceHit;
    @Input() currentYear: string;
    private _calcData: CalcResponse;
    yearHit: YearHit;

    @Input()
    set calcData(data: CalcResponse) {
        if (data) {
            this._calcData = data;
            this.yearHit = data.hit[this.currentYear];
        }
    }

    get calcData() {
        return this._calcData;
    }

    constructor() { }

    getLabels() {
        const min = Math.round(this.getMin() / 1000) * 1000,
            max = Math.round(this.getMax() / 1000) * 1000,
            step = (max - min) / 5;

        return [
            min,
            min + step,
            min + step + step,
            min + step + step + step,
            min + step + step + step + step,
            max
        ];
    }

    getPointPosition(price: number): number {
        return (100 * (price - this.getMin())) / (this.getMax() - this.getMin());
    }

    getLineStylesObj(i: number, j: number, sign: boolean) {
        const a = [this.getPointPosition(i), 0],
            b = [this.getPointPosition(j), 20.161],
            x = a[0] - b[0],
            y = a[1] - b[1],
            distance = Math.sqrt(x * x + y * y),
            radian = Math.atan2(b[1] - a[1], b[0] - a[0]),
            degrees = (radian * 180) / Math.PI;

        return {
            width: distance + '%',
            top: a[1] + '%',
            left: 'calc(' + (a[0] + 1) + (sign ? '% + 4px)' : '% - 4px)'),
            transform: 'rotate("+degrees+"deg)'
        };
    }

    getThousandValue(price: number): number {
        return Math.round(price / 100) / 10;
    }

    private getMin(): number {
        return this.yearHit.min * 0.9;
    }

    private getMax(): number {
        return this.yearHit.max * 1.1;
    }
}
