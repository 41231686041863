import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
        <div class="mat-typography">
            <h3 i18n="Solidity tooltip heading@@solidity-tooltip-heading">Solidity of the data model / how “confident” is the FMPR?</h3>
            <ul>
                <li i18n="First bullet text in solidity tooltip@@solidity-tooltip-first">
                    The quality of the model = <strong>number of observations</strong> and r-squared of the least square regression
                </li>
                <li i18n="Second bullet text in solidity tooltip@@solidity-tooltip-second">
                    The “confidence” of the final range = <strong>Weak model</strong> = results should be
                    considered as a price indication. <strong>Solid model</strong> = good confidence
                    for a reliable fair market value estimate. <strong>Outstanding model</strong> = Very good confidence
                    for the price range.
                </li>
            </ul>
            <button style="float:right" mat-raised-button (click)="onClick()" i18n="Tooltip close button@@tooltip-close">Close</button>
            <div style="clear:both"></div>
        </div>
    `,
})
export class SolidityTooltip {

  constructor(public dialogRef: MatDialogRef<SolidityTooltip>) {}

  onClick(): void {
    this.dialogRef.close();
  }

}
