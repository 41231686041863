import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
        <div class="mat-typography">
            <h3 i18n="Value curve tooltip heading@@tooltip-value-curve-heading">What does this section tell you?</h3>
            <ul>
                <li i18n="Value curve tooltip first bullet@@tooltip-value-curve-first">
                    Visualisation of all collected data points and the statistical model followed by a table with the detailed data.
                </li>
            </ul>
            <h3 i18n="Value curve tooltip heading 2@@tooltip-value-curve-heading-2">How to deal with the chart?</h3>
            <ul>
                <li i18n="Value curve tooltip second bullet@@tooltip-value-curve-second"><strong>Raw data points</strong> = all observations of market transactions collected</li>
                <li i18n="Value curve tooltip third bullet@@tooltip-value-curve-third">
                    <strong>Included data points</strong> = Algorithmically and statistically cleansed datarange used in
                    the mathematical model, thus: relevant data range, cleaned by edge cases and other
                    automatically detected implausibilities.
                </li>
                <li i18n="Value curve tooltip fourth bullet@@tooltip-value-curve-fourth"><strong>FMV indication</strong> = Most realistic price probability as a result of the least square regression.</li>
            </ul>
            <h4 style="color: #fb9803; font-weight: 500" i18n="Value curve tooltip heading 3@@tooltip-value-curve-heading-3">
                Click on the legend icons to show and unshow the chart’s elements for a custom overview.
            </h4>
            <button style="float:right" mat-raised-button (click)="onClick()" i18n="Tooltip close button@@tooltip-close">Close</button>
            <div style="clear:both"></div>
        </div>
    `,
})
export class ValueCurveTooltip {

  constructor(public dialogRef: MatDialogRef<ValueCurveTooltip>) {}

  onClick(): void {
    this.dialogRef.close();
  }

}
