import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CalcResponse, CalcRequest, ListingDescription } from '../response/calculation';

const API_KEY = 'de@lyT@@ly18';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {
    private url = 'https://wsng.lectura.de';

    constructor(private http: HttpClient) { }

    getData(request: CalcRequest): Observable<CalcResponse> {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/x-www-form-urlencoded'
            })
        };
        request.response = 'JSON';
        request.apiKey = API_KEY;
        return this.http.post<CalcResponse>(`${this.url}/appraisal/`, request.toString(), options);
    }

    getDescription(id: number): Observable<ListingDescription> {
        let req = new HttpParams();
        req = req.append('apiKey', API_KEY);
        req = req.append('id', id.toString());
        return this.http.get<ListingDescription>(`${this.url}/description/?${req.toString()}`);
    }
}
