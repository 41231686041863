import { Component, Input, Inject } from '@angular/core';
import { ListingData, ListingDescription } from '../response/calculation';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CalculationService } from '../services/calculation.service';

export interface DialogData {
  id: number;
}

@Component({
  selector: 'app-listing-info-popup',
  templateUrl: './listing-info-popup.component.html',
  styleUrls: ['./listing-info-popup.component.css']
})
export class ListingInfoPopupComponent {
  @Input() listing: ListingData;

  constructor(public dialog: MatDialog) { }

  open(): void {
    const dialogRef = this.dialog.open(ListingInfoDialogComponent, {
      width: '500px',
      data: {
        id: this.listing.id
      }
    });
  }
}

@Component({
  selector: 'dialog-listing-info',
  templateUrl: './dialog-listing-info.html'
})
export class ListingInfoDialogComponent {
  description: ListingDescription;
  loading = true;

  constructor(
    public dialogRef: MatDialogRef<ListingInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service: CalculationService
  ) {
    this.service.getDescription(data.id).subscribe((desc: ListingDescription) => {this.description = desc; this.loading = false; });
  }
}
