import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as boost from 'highcharts/modules/boost.src';
import * as highmaps from 'highcharts/modules/map.src';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';

import { AppComponent } from './app.component';
import { MainRoutingModule } from './main-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { HomepageComponent } from './homepage/homepage.component';
import { DetailComponent, ComingSoonDialogComponent } from './detail/detail.component';
import { ModelCardComponent } from './model-card/model-card.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';

import { ModelProvider } from './providers/model.provider';
import { ModelInfoCardsComponent } from './model-info-cards/model-info-cards.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { RegionSelectComponent } from './region-select/region-select.component';
import { CalculationGraphComponent } from './calculation-graph/calculation-graph.component';
import { ForecastGraphComponent } from './forecast-graph/forecast-graph.component';
import { AvailabilityGraphComponent } from './availability-graph/availability-graph.component';
import { CountryGraphComponent } from './country-graph/country-graph.component';
import { ModelOptionsComponent } from './model-options/model-options.component';
import { ListingsTableComponent } from './listings-table/listings-table.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FMVTooltip } from './tooltips/FMVTooltip';
import { ModelOptionsTooltip } from './tooltips/ModelOptionsTooltip';
import { SolidityTooltip } from './tooltips/SolidityTooltip';
import { ValueCurveTooltip } from './tooltips/ValueCurveTooltip';
import { ListingsTableTooltip } from './tooltips/ListingsTableTooltip';
import { AvailabilityTooltip } from './tooltips/AvailabilityTooltip';
import { MarginDialogComponent } from './margin-popup/margin-popup.component';
import { AccessoryDialogComponent } from './accessory-popup/accessory-popup.component';
import { MenuService } from './services/menu.service';
import { SearchService } from './services/search.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SearchFormHeaderComponent } from './search-form-header/search-form-header.component';
import { FilterFormComponent } from './filter-form/filter-form.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { PriceTableComponent } from './price-table/price-table.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MarginPopupComponent } from './margin-popup/margin-popup.component';
import { AccessoryPopupComponent } from './accessory-popup/accessory-popup.component';
import { ListingInfoPopupComponent, ListingInfoDialogComponent } from './listing-info-popup/listing-info-popup.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { SpecsBoxComponent } from './specs-box/specs-box.component';
import { AccessoriesTableComponent } from './accessories-table/accessories-table.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    DetailComponent,
    BreadcrumbsComponent,
    ModelCardComponent,
    ModelInfoCardsComponent,
    CountrySelectComponent,
    RegionSelectComponent,
    CalculationGraphComponent,
    ForecastGraphComponent,
    AvailabilityGraphComponent,
    CountryGraphComponent,
    ModelOptionsComponent,
    ListingsTableComponent,
    TooltipComponent,
    FMVTooltip,
    ModelOptionsTooltip,
    SolidityTooltip,
    ValueCurveTooltip,
    ListingsTableTooltip,
    AvailabilityTooltip,
    MarginDialogComponent,
    AccessoryDialogComponent,
    ListingInfoDialogComponent,
    ComingSoonDialogComponent,
    FeedbackComponent,
    SearchFormComponent,
    SearchFormHeaderComponent,
    FilterFormComponent,
    SearchResultsComponent,
    PriceTableComponent,
    LanguageSelectorComponent,
    MarginPopupComponent,
    AccessoryPopupComponent,
    ListingInfoPopupComponent,
    SpecsBoxComponent,
    AccessoriesTableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MainRoutingModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    HttpClientModule,
    ChartModule
  ],
  providers: [
    ModelProvider,
    MenuService,
    SearchService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, boost, highmaps ] }
  ],
  entryComponents: [
    FMVTooltip,
    ModelOptionsTooltip,
    SolidityTooltip,
    ValueCurveTooltip,
    ListingsTableTooltip,
    AvailabilityTooltip,
    MarginDialogComponent,
    AccessoryDialogComponent,
    ListingInfoDialogComponent,
    ComingSoonDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
