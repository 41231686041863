import { Component, Input } from '@angular/core';
import { CalcResponse, YearHit, CalcRequest } from '../response/calculation';
import { LecturaModelSourceHit } from '../response/elastic';
import { numberFormat, proportion } from '../utils/template';
import { countries } from '../utils/countries';
import { PriceCustomizationService } from '../services/price-customization.service';
import { FMVRange, FMVService } from '../services/fmv.service';
import { CONDITIONS, CONDITIONS_DE } from '../price-customizations/condition-customization';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'model-info-cards',
  templateUrl: './model-info-cards.component.html',
  styleUrls: ['./model-info-cards.component.css']
})
export class ModelInfoCardsComponent {
    yearHit: YearHit;
    fmvRange: FMVRange;
    private _calcData: CalcResponse;
    @Input() model: LecturaModelSourceHit;
    @Input() currentYear: number;
    @Input() request: CalcRequest;
    hasAccessories = false;
    isOpen: boolean;

    constructor(
        public customizations: PriceCustomizationService,
        private fmvService: FMVService,
        private language: LanguageService
    ) {
        this.isOpen = false;
        this.fmvRange = {low: null, high: null};

        this.fmvService.fmvChanged.subscribe(() => {
            this.fmvRange = this.fmvService.getFMVRange(this._calcData, this.currentYear);
            this.hasAccessories = this.fmvService.hasAccessories();
        });
    }

    modelImage(image: string) {
        let src = 'https://www.lectura-specs.com/assets/frontend/dist/images/icons/placeholder_d.png';

        if (image) {
            src = 'https://specs.lectura.de/models/renamed/list_desktop_retina/' + image;
        }

        return src;
    }

    @Input()
    set calcData(data: CalcResponse) {
        if (data) {
            this._calcData = data;
            if (!this.currentYear) {
                this.currentYear = data.hasOwnProperty('hit') && data.hit.length > 0
                    ? (new Date()).getFullYear() - data.hit[0].age
                    : (new Date().getFullYear() - 1);
            }

            this.yearHit = this.fmvService.getYearHit(data, this.currentYear);
            this.fmvRange = this.fmvService.getFMVRange(data, this.currentYear);
            this.hasAccessories = this.fmvService.hasAccessories();
        }
    }

    get calcData(): CalcResponse {
        return this._calcData;
    }

    formatPrice(price: number) {
        return numberFormat(price);
    }

    priceComparisonIcon(current: number, previous: number): string {
        const x = ((100 * current) / previous) - 100;
        return x > 0 ? 'expand_less' : 'expand_more';
    }

    priceComparisonPercentage(current: number, previous: number): number {
        const x = ((100 * current) / previous) - 100;
        return Math.abs(Math.round(x));
    }

    medianPercentage(current: number, previous: number): number {
        return Math.abs(proportion(current, previous));
    }

    toggle(event) {
        event.preventDefault();
        this.isOpen = !this.isOpen;
    }

    countryName(short: string[]): string {
        let res = '';

        for (let i = 0; i < 3; i++) {
            if (short.hasOwnProperty(i)) {
                res += countries[short[i]].name[this.language.locale] + ',';
            }
        }

        res = res.slice(0, res.length - 1);

        if (short.length > 3) {
            res += ` and ${short.length - 3} more`;
        }

        return res;
    }

    regressionQualityText() {
        let result = this.language.locale ? 'Outstanding model' : 'Außergewöhnlich hohe Belastbarkeit';

        if (this._calcData.hasOwnProperty('backgrounds')) {
            if (this._calcData.backgrounds.quality === 'solid') {
                result = this.language.locale === 'en' ? 'Solid model' : 'Solide Belastbarkeit';
            } else if (this._calcData.backgrounds.quality === 'weak') {
                result = this.language.locale === 'en' ? 'Weak model' : 'Niedrigere Belastbarkeit';
            }
        } else {
            result = this.language.locale === 'en' ? 'Weak model' : 'Niedrigere Belastbarkeit';
        }

        return result;
    }

    regressionStyleClass() {
        let result = 'text-green';

        if (!this._calcData.hasOwnProperty('backgrounds') || this._calcData.backgrounds.quality === 'weak') {
            result = 'text-red';
        }

        return result;
    }

    observationsCount() {
        return this._calcData.hasOwnProperty('backgrounds') ? this._calcData.backgrounds.observation : this._calcData.data.length;
    }

    conditionText(condition: number) {
        return this.language.locale === 'en' ? CONDITIONS[condition] : CONDITIONS_DE[condition];
    }
}
