import { Injectable } from '@angular/core';
import { LecturaModelSourceHit, LecturaModelResponse} from '../response/elastic';
import { ActivatedRoute } from '@angular/router';
import { ElasticService } from '../services/elastic.service';

@Injectable({
  providedIn: 'root',
})
export class ModelProvider {
    private _current: LecturaModelSourceHit;

    constructor(private route: ActivatedRoute, private els: ElasticService) {}

    set current(model: LecturaModelSourceHit) {
        this._current = model;
    }

    get current(): LecturaModelSourceHit {
        return this._current;
    }
}
