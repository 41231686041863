import { Component, Input, ComponentFactoryResolver } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FMVTooltip } from '../tooltips/FMVTooltip';
import { ModelOptionsTooltip } from '../tooltips/ModelOptionsTooltip';
import { SolidityTooltip } from '../tooltips/SolidityTooltip';
import { ValueCurveTooltip } from '../tooltips/ValueCurveTooltip';
import { ListingsTableTooltip } from '../tooltips/ListingsTableTooltip';
import { AvailabilityTooltip } from '../tooltips/AvailabilityTooltip';

const availableDialogs = {
    'FMVTooltip': FMVTooltip,
    'ModelOptionsTooltip': ModelOptionsTooltip,
    'SolidityTooltip': SolidityTooltip,
    'ValueCurveTooltip': ValueCurveTooltip,
    'ListingsTableTooltip': ListingsTableTooltip,
    'AvailabilityTooltip': AvailabilityTooltip
};

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {
    @Input() iconClass: string;
    @Input() component: string;

    constructor(public dialog: MatDialog) { }

    openDialog() {
        if (!availableDialogs[this.component]) {
            throw new Error(`Unsupported dialog: "${this.component}"`);
        }

        const dialogRef = this.dialog.open(availableDialogs[this.component], {
            autoFocus: false
        });
    }
}
