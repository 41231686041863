import { Component, ElementRef, HostListener } from '@angular/core';
import { LocalStorageService } from '../services/localStorage.service';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {

    effectClass = '';

    constructor(private localStorage: LocalStorageService, private menuService: MenuService, private eRef: ElementRef) {
        let count = this.localStorage.get('feedback-seen') || 0;

        if (count === 3) {
            this.effectClass = 'feedback-closed';
        } else {
            count = count + 1;
            this.localStorage.set('feedback-seen', count);
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.effectClass = 'feedback-closed';
        }
    }

    close(event) {
        event.preventDefault();
        this.effectClass = 'feedback-closed';
    }
}
