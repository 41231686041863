import { Injectable } from '@angular/core';
import { MarginService } from './margin.service';
import { AccessoryService } from './accessory.service';
import { PriceCustomizationService } from './price-customization.service';
import { CalcResponse, YearHit } from '../response/calculation';
import { Subject, merge } from 'rxjs';

export interface FMVRange {
    low: number;
    high: number;
}

const HOURS_HIT_HIGH_FMV = 1.1;

@Injectable({
  providedIn: 'root'
})
export class FMVService {
    private fmvChanging = new Subject<void>();

    fmvChanged = this.fmvChanging.asObservable();

    constructor(
        private margins: MarginService,
        private accessories: AccessoryService,
        private customizations: PriceCustomizationService,
    ) {
        merge(
            this.margins.dataChanged,
            this.accessories.dataChanged
        ).subscribe(() => {
            this.fmvChanging.next();
        });
    }

    getFMVRange(data: CalcResponse, year?: number): FMVRange {
        const yearHit = this.getYearHit(data, year),
              range = {low: null, high: null};

        if (yearHit) {
            range.low = yearHit.fmv;
            range.high = yearHit.highCI;

            if (data.hoursHit) {
                range.low = data.hoursHit.fmv;
                range.high = range.low * HOURS_HIT_HIGH_FMV;
            }

            range.low = this.customizations.calculate(range.low);
            range.high = this.customizations.calculate(range.high);
        }

        return range;
    }

    getFMV(data: CalcResponse, year?: number): number {
        const yearHit = data.hit.filter((item: YearHit) => item.age === year).pop();
        return this.customizations.calculate(yearHit.fmv);
    }

    getYearHit(data: CalcResponse, year?: number): YearHit {
        let yearHit = null;

        if (!year) {
            year = data.hasOwnProperty('hit') && data.hit.length > 0
                ? (new Date()).getFullYear() - data.hit[0].age
                : (new Date().getFullYear() - 1);
        }

        if (data.hasOwnProperty('yearHit') && data.yearHit.status) {
            yearHit = data.yearHit;
        } else if (data.hasOwnProperty('hit')) {
            yearHit = data.hit.filter((item: YearHit) => item.age === (new Date()).getFullYear() - year).pop();
        }

        return yearHit;
    }

    hasAccessories(): boolean {
        return this.accessories.loadActive().length > 0;
    }
}
