import { Component } from '@angular/core';
import { AccessoryService, Accessory } from '../services/accessory.service';
import { numberFormat } from '../utils/template';

@Component({
  selector: 'app-accessories-table',
  templateUrl: './accessories-table.component.html',
  styleUrls: ['./accessories-table.component.css']
})
export class AccessoriesTableComponent {
    accs: Accessory[];
    visible = false;

    constructor(
        private accessories: AccessoryService
    ) {
        this.accs = this.accessories.loadActive();
        this.visible = this.accs.length > 0;

        this.accessories.dataChanged.subscribe((data: Accessory[]) => {
            this.accs = data;
            this.visible = this.accs.length > 0;
        });
    }

    numberFormat(nr: number): string {
        return numberFormat(nr);
    }
}
