import { Injectable } from '@angular/core';
import { CalcResponse, YearHit } from '../response/calculation';
import { PriceCustomizationService } from './price-customization.service';
import { MarginService } from './margin.service';

@Injectable({
  providedIn: 'root'
})
export class GraphTooltipService {
  private data: CalcResponse;

  constructor(
      private customizations: PriceCustomizationService,
      private margins: MarginService
  ) { }

  setData(data: CalcResponse) {
      this.data = data;
  }

  getLowCI(year: number): number {
      return this.data.hit.filter((item: YearHit) => item.age === year).pop().lowCI;
  }

  getHighCI(year: number): number {
      return this.data.hit.filter((item: YearHit) => item.age === year).pop().highCI;
  }

  getFMV(year: number): number {
      return this.customizations.calculate(this.data.hit.filter((item: YearHit) => item.age === year).pop().fmv);
  }
}
