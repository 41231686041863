import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
        <div class="mat-typography">
            <h3>What do the charts tell me?</h3>
            <ul>
                <li>
                    Market monitor: Indicates the number of the chosen machine
                    variants available on the observed markets / countries over time.
                </li>
            </ul>
            <button style="float:right" mat-raised-button (click)="onClick()">Close</button>
            <div style="clear:both"></div>
        </div>
    `,
})
export class AvailabilityTooltip {

  constructor(public dialogRef: MatDialogRef<AvailabilityTooltip>) {}

  onClick(): void {
    this.dialogRef.close();
  }

}
