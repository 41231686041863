import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';
import { DetailComponent } from './detail/detail.component';

const appRoutes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'search', component: DetailComponent },
  { path: '**', redirectTo: '/' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
          enableTracing: false,
          scrollPositionRestoration: 'enabled',
          anchorScrolling: 'enabled'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {}
