export interface Country {
    id: string;
    name: {[key: string]: string};
}
export const countries: {[key: string]: Country} = {
  AD: {
    id: 'AD',
    name: {
      en: 'Andorra',
      de: 'Andorra'
    }
  },
  AE: {
    id: 'AE',
    name: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate'
    }
  },
  AF: {
    id: 'AF',
    name: {
      en: 'Afghanistan',
      de: 'Afghanistan'
    }
  },
  AG: {
    id: 'AG',
    name: {
      en: 'Antigua and Barbuda',
      de: 'Antigua und Barbuda'
    }
  },
  AI: {
    id: 'AI',
    name: {
      en: 'Anguilla',
      de: 'Anguilla'
    }
  },
  AL: {
    id: 'AL',
    name: {
      en: 'Albania',
      de: 'Albanien'
    }
  },
  AM: {
    id: 'AM',
    name: {
      en: 'Armenia',
      de: 'Armenien'
    }
  },
  AN: {
    id: 'AN',
    name: {
      en: 'Netherlands Antilles',
      de: 'Niederländische Antillen'
    }
  },
  AO: {
    id: 'AO',
    name: {
      en: 'Angola',
      de: 'Angola'
    }
  },
  AQ: {
    id: 'AQ',
    name: {
      en: 'Antarctica',
      de: 'Antarktis'
    }
  },
  AR: {
    id: 'AR',
    name: {
      en: 'Argentina',
      de: 'Argentinien'
    }
  },
  AS: {
    id: 'AS',
    name: {
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa'
    }
  },
  AT: {
    id: 'AT',
    name: {
      en: 'Austria',
      de: 'Österreich'
    }
  },
  AU: {
    id: 'AU',
    name: {
      en: 'Australia',
      de: 'Australien'
    }
  },
  AW: {
    id: 'AW',
    name: {
      en: 'Aruba',
      de: 'Aruba'
    }
  },
  AX: {
    id: 'AX',
    name: {
      en: 'Åland Islands',
      de: 'Alandinseln'
    }
  },
  AZ: {
    id: 'AZ',
    name: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan'
    }
  },
  BA: {
    id: 'BA',
    name: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina'
    }
  },
  BB: {
    id: 'BB',
    name: {
      en: 'Barbados',
      de: 'Barbados'
    }
  },
  BD: {
    id: 'BD',
    name: {
      en: 'Bangladesh',
      de: 'Bangladesch'
    }
  },
  BE: {
    id: 'BE',
    name: {
      en: 'Belgium',
      de: 'Belgien'
    }
  },
  BF: {
    id: 'BF',
    name: {
      en: 'Burkina Faso',
      de: 'Burkina Faso'
    }
  },
  BG: {
    id: 'BG',
    name: {
      en: 'Bulgaria',
      de: 'Bulgarien'
    }
  },
  BH: {
    id: 'BH',
    name: {
      en: 'Bahrain',
      de: 'Bahrain'
    }
  },
  BI: {
    id: 'BI',
    name: {
      en: 'Burundi',
      de: 'Burundi'
    }
  },
  BJ: {
    id: 'BJ',
    name: {
      en: 'Benin',
      de: 'Benin'
    }
  },
  BL: {
    id: 'BL',
    name: {
      en: 'Saint Barthélemy',
      de: 'St. Barthélemy'
    }
  },
  BM: {
    id: 'BM',
    name: {
      en: 'Bermuda',
      de: 'Bermuda'
    }
  },
  BN: {
    id: 'BN',
    name: {
      en: 'Brunei',
      de: 'Brunei Darussalam'
    }
  },
  BO: {
    id: 'BO',
    name: {
      en: 'Bolivia',
      de: 'Bolivien'
    }
  },
  BR: {
    id: 'BR',
    name: {
      en: 'Brazil',
      de: 'Brasilien'
    }
  },
  BS: {
    id: 'BS',
    name: {
      en: 'Bahamas',
      de: 'Bahamas'
    }
  },
  BT: {
    id: 'BT',
    name: {
      en: 'Bhutan',
      de: 'Bhutan'
    }
  },
  BV: {
    id: 'BV',
    name: {
      en: 'Bouvet Island',
      de: 'Bouvetinsel'
    }
  },
  BW: {
    id: 'BW',
    name: {
      en: 'Botswana',
      de: 'Botsuana'
    }
  },
  BY: {
    id: 'BY',
    name: {
      en: 'Belarus',
      de: 'Belarus'
    }
  },
  BZ: {
    id: 'BZ',
    name: {
      en: 'Belize',
      de: 'Belize'
    }
  },
  CA: {
    id: 'CA',
    name: {
      en: 'Canada',
      de: 'Kanada'
    }
  },
  CC: {
    id: 'CC',
    name: {
      en: 'Cocos [Keeling] Islands',
      de: 'Kokosinseln'
    }
  },
  CD: {
    id: 'CD',
    name: {
      en: 'Congo - Kinshasa',
      de: 'Demokratische Republik Kongo'
    }
  },
  CF: {
    id: 'CF',
    name: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik'
    }
  },
  CG: {
    id: 'CG',
    name: {
      en: 'Congo - Brazzaville',
      de: 'Kongo'
    }
  },
  CH: {
    id: 'CH',
    name: {
      en: 'Switzerland',
      de: 'Schweiz'
    }
  },
  CI: {
    id: 'CI',
    name: {
      en: 'Côte d’Ivoire',
      de: 'Côte d’Ivoire'
    }
  },
  CK: {
    id: 'CK',
    name: {
      en: 'Cook Islands',
      de: 'Cookinseln'
    }
  },
  CL: {
    id: 'CL',
    name: {
      en: 'Chile',
      de: 'Chile'
    }
  },
  CM: {
    id: 'CM',
    name: {
      en: 'Cameroon',
      de: 'Kamerun'
    }
  },
  CN: {
    id: 'CN',
    name: {
      en: 'China',
      de: 'China'
    }
  },
  CO: {
    id: 'CO',
    name: {
      en: 'Colombia',
      de: 'Kolumbien'
    }
  },
  CR: {
    id: 'CR',
    name: {
      en: 'Costa Rica',
      de: 'Costa Rica'
    }
  },
  CS: {
    id: 'CS',
    name: {
      en: 'Serbia and Montenegro',
      de: 'Serbien und Montenegro'
    }
  },
  CU: {
    id: 'CU',
    name: {
      en: 'Cuba',
      de: 'Kuba'
    }
  },
  CV: {
    id: 'CV',
    name: {
      en: 'Cape Verde',
      de: 'Kap Verde'
    }
  },
  CX: {
    id: 'CX',
    name: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel'
    }
  },
  CY: {
    id: 'CY',
    name: {
      en: 'Cyprus',
      de: 'Zypern'
    }
  },
  CZ: {
    id: 'CZ',
    name: {
      en: 'Czech Republic',
      de: 'Tschechische Republik'
    }
  },
  DE: {
    id: 'DE',
    name: {
      en: 'Germany',
      de: 'Deutschland'
    }
  },
  DJ: {
    id: 'DJ',
    name: {
      en: 'Djibouti',
      de: 'Dschibuti'
    }
  },
  DK: {
    id: 'DK',
    name: {
      en: 'Denmark',
      de: 'Dänemark'
    }
  },
  DM: {
    id: 'DM',
    name: {
      en: 'Dominica',
      de: 'Dominica'
    }
  },
  DO: {
    id: 'DO',
    name: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik'
    }
  },
  DZ: {
    id: 'DZ',
    name: {
      en: 'Algeria',
      de: 'Algerien'
    }
  },
  EC: {
    id: 'EC',
    name: {
      en: 'Ecuador',
      de: 'Ecuador'
    }
  },
  EE: {
    id: 'EE',
    name: {
      en: 'Estonia',
      de: 'Estland'
    }
  },
  EG: {
    id: 'EG',
    name: {
      en: 'Egypt',
      de: 'Ägypten'
    }
  },
  EH: {
    id: 'EH',
    name: {
      en: 'Western Sahara',
      de: 'Westsahara'
    }
  },
  ER: {
    id: 'ER',
    name: {
      en: 'Eritrea',
      de: 'Eritrea'
    }
  },
  ES: {
    id: 'ES',
    name: {
      en: 'Spain',
      de: 'Spanien'
    }
  },
  ET: {
    id: 'ET',
    name: {
      en: 'Ethiopia',
      de: 'Äthiopien'
    }
  },
  FI: {
    id: 'FI',
    name: {
      en: 'Finland',
      de: 'Finnland'
    }
  },
  FJ: {
    id: 'FJ',
    name: {
      en: 'Fiji',
      de: 'Fidschi'
    }
  },
  FK: {
    id: 'FK',
    name: {
      en: 'Falkland Islands',
      de: 'Falklandinseln'
    }
  },
  FM: {
    id: 'FM',
    name: {
      en: 'Micronesia',
      de: 'Mikronesien'
    }
  },
  FO: {
    id: 'FO',
    name: {
      en: 'Faroe Islands',
      de: 'Färöer'
    }
  },
  FR: {
    id: 'FR',
    name: {
      en: 'France',
      de: 'Frankreich'
    }
  },
  GA: {
    id: 'GA',
    name: {
      en: 'Gabon',
      de: 'Gabun'
    }
  },
  GB: {
    id: 'GB',
    name: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich'
    }
  },
  GD: {
    id: 'GD',
    name: {
      en: 'Grenada',
      de: 'Grenada'
    }
  },
  GE: {
    id: 'GE',
    name: {
      en: 'Georgia',
      de: 'Georgien'
    }
  },
  GF: {
    id: 'GF',
    name: {
      en: 'French Guiana',
      de: 'Französisch-Guayana'
    }
  },
  GG: {
    id: 'GG',
    name: {
      en: 'Guernsey',
      de: 'Guernsey'
    }
  },
  GH: {
    id: 'GH',
    name: {
      en: 'Ghana',
      de: 'Ghana'
    }
  },
  GI: {
    id: 'GI',
    name: {
      en: 'Gibraltar',
      de: 'Gibraltar'
    }
  },
  GL: {
    id: 'GL',
    name: {
      en: 'Greenland',
      de: 'Grönland'
    }
  },
  GM: {
    id: 'GM',
    name: {
      en: 'Gambia',
      de: 'Gambia'
    }
  },
  GN: {
    id: 'GN',
    name: {
      en: 'Guinea',
      de: 'Guinea'
    }
  },
  GP: {
    id: 'GP',
    name: {
      en: 'Guadeloupe',
      de: 'Guadeloupe'
    }
  },
  GQ: {
    id: 'GQ',
    name: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea'
    }
  },
  GR: {
    id: 'GR',
    name: {
      en: 'Greece',
      de: 'Griechenland'
    }
  },
  GS: {
    id: 'GS',
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Südgeorgien und die Südlichen Sandwichinseln'
    }
  },
  GT: {
    id: 'GT',
    name: {
      en: 'Guatemala',
      de: 'Guatemala'
    }
  },
  GU: {
    id: 'GU',
    name: {
      en: 'Guam',
      de: 'Guam'
    }
  },
  GW: {
    id: 'GW',
    name: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau'
    }
  },
  GY: {
    id: 'GY',
    name: {
      en: 'Guyana',
      de: 'Guyana'
    }
  },
  HK: {
    id: 'HK',
    name: {
      en: 'Hong Kong SAR China',
      de: 'Sonderverwaltungszone Hongkong'
    }
  },
  HM: {
    id: 'HM',
    name: {
      en: 'Heard Island and McDonald Islands',
      de: 'Heard- und McDonald-Inseln'
    }
  },
  HN: {
    id: 'HN',
    name: {
      en: 'Honduras',
      de: 'Honduras'
    }
  },
  HR: {
    id: 'HR',
    name: {
      en: 'Croatia',
      de: 'Kroatien'
    }
  },
  HT: {
    id: 'HT',
    name: {
      en: 'Haiti',
      de: 'Haiti'
    }
  },
  HU: {
    id: 'HU',
    name: {
      en: 'Hungary',
      de: 'Ungarn'
    }
  },
  ID: {
    id: 'ID',
    name: {
      en: 'Indonesia',
      de: 'Indonesien'
    }
  },
  IE: {
    id: 'IE',
    name: {
      en: 'Ireland',
      de: 'Irland'
    }
  },
  IL: {
    id: 'IL',
    name: {
      en: 'Israel',
      de: 'Israel'
    }
  },
  IM: {
    id: 'IM',
    name: {
      en: 'Isle of Man',
      de: 'Isle of Man'
    }
  },
  IN: {
    id: 'IN',
    name: {
      en: 'India',
      de: 'Indien'
    }
  },
  IO: {
    id: 'IO',
    name: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean'
    }
  },
  IQ: {
    id: 'IQ',
    name: {
      en: 'Iraq',
      de: 'Irak'
    }
  },
  IR: {
    id: 'IR',
    name: {
      en: 'Iran',
      de: 'Iran'
    }
  },
  IS: {
    id: 'IS',
    name: {
      en: 'Iceland',
      de: 'Island'
    }
  },
  IT: {
    id: 'IT',
    name: {
      en: 'Italy',
      de: 'Italien'
    }
  },
  JE: {
    id: 'JE',
    name: {
      en: 'Jersey',
      de: 'Jersey'
    }
  },
  JM: {
    id: 'JM',
    name: {
      en: 'Jamaica',
      de: 'Jamaika'
    }
  },
  JO: {
    id: 'JO',
    name: {
      en: 'Jordan',
      de: 'Jordanien'
    }
  },
  JP: {
    id: 'JP',
    name: {
      en: 'Japan',
      de: 'Japan'
    }
  },
  KE: {
    id: 'KE',
    name: {
      en: 'Kenya',
      de: 'Kenia'
    }
  },
  KG: {
    id: 'KG',
    name: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan'
    }
  },
  KH: {
    id: 'KH',
    name: {
      en: 'Cambodia',
      de: 'Kambodscha'
    }
  },
  KI: {
    id: 'KI',
    name: {
      en: 'Kiribati',
      de: 'Kiribati'
    }
  },
  KM: {
    id: 'KM',
    name: {
      en: 'Comoros',
      de: 'Komoren'
    }
  },
  KN: {
    id: 'KN',
    name: {
      en: 'Saint Kitts and Nevis',
      de: 'St. Kitts und Nevis'
    }
  },
  KP: {
    id: 'KP',
    name: {
      en: 'North Korea',
      de: 'Demokratische Volksrepublik Korea'
    }
  },
  KR: {
    id: 'KR',
    name: {
      en: 'South Korea',
      de: 'Republik Korea'
    }
  },
  KW: {
    id: 'KW',
    name: {
      en: 'Kuwait',
      de: 'Kuwait'
    }
  },
  KY: {
    id: 'KY',
    name: {
      en: 'Cayman Islands',
      de: 'Kaimaninseln'
    }
  },
  KZ: {
    id: 'KZ',
    name: {
      en: 'Kazakhstan',
      de: 'Kasachstan'
    }
  },
  LA: {
    id: 'LA',
    name: {
      en: 'Laos',
      de: 'Laos'
    }
  },
  LB: {
    id: 'LB',
    name: {
      en: 'Lebanon',
      de: 'Libanon'
    }
  },
  LC: {
    id: 'LC',
    name: {
      en: 'Saint Lucia',
      de: 'St. Lucia'
    }
  },
  LI: {
    id: 'LI',
    name: {
      en: 'Liechtenstein',
      de: 'Liechtenstein'
    }
  },
  LK: {
    id: 'LK',
    name: {
      en: 'Sri Lanka',
      de: 'Sri Lanka'
    }
  },
  LR: {
    id: 'LR',
    name: {
      en: 'Liberia',
      de: 'Liberia'
    }
  },
  LS: {
    id: 'LS',
    name: {
      en: 'Lesotho',
      de: 'Lesotho'
    }
  },
  LT: {
    id: 'LT',
    name: {
      en: 'Lithuania',
      de: 'Litauen'
    }
  },
  LU: {
    id: 'LU',
    name: {
      en: 'Luxembourg',
      de: 'Luxemburg'
    }
  },
  LV: {
    id: 'LV',
    name: {
      en: 'Latvia',
      de: 'Lettland'
    }
  },
  LY: {
    id: 'LY',
    name: {
      en: 'Libya',
      de: 'Libyen'
    }
  },
  MA: {
    id: 'MA',
    name: {
      en: 'Morocco',
      de: 'Marokko'
    }
  },
  MC: {
    id: 'MC',
    name: {
      en: 'Monaco',
      de: 'Monaco'
    }
  },
  MD: {
    id: 'MD',
    name: {
      en: 'Moldova',
      de: 'Republik Moldau'
    }
  },
  ME: {
    id: 'ME',
    name: {
      en: 'Montenegro',
      de: 'Montenegro'
    }
  },
  MF: {
    id: 'MF',
    name: {
      en: 'Saint Martin',
      de: 'St. Martin'
    }
  },
  MG: {
    id: 'MG',
    name: {
      en: 'Madagascar',
      de: 'Madagaskar'
    }
  },
  MH: {
    id: 'MH',
    name: {
      en: 'Marshall Islands',
      de: 'Marshallinseln'
    }
  },
  MK: {
    id: 'MK',
    name: {
      en: 'Macedonia',
      de: 'Mazedonien'
    }
  },
  ML: {
    id: 'ML',
    name: {
      en: 'Mali',
      de: 'Mali'
    }
  },
  MM: {
    id: 'MM',
    name: {
      en: 'Myanmar [Burma]',
      de: 'Myanmar'
    }
  },
  MN: {
    id: 'MN',
    name: {
      en: 'Mongolia',
      de: 'Mongolei'
    }
  },
  MO: {
    id: 'MO',
    name: {
      en: 'Macau SAR China',
      de: 'Sonderverwaltungszone Macao'
    }
  },
  MP: {
    id: 'MP',
    name: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianen'
    }
  },
  MQ: {
    id: 'MQ',
    name: {
      en: 'Martinique',
      de: 'Martinique'
    }
  },
  MR: {
    id: 'MR',
    name: {
      en: 'Mauritania',
      de: 'Mauretanien'
    }
  },
  MS: {
    id: 'MS',
    name: {
      en: 'Montserrat',
      de: 'Montserrat'
    }
  },
  MT: {
    id: 'MT',
    name: {
      en: 'Malta',
      de: 'Malta'
    }
  },
  MU: {
    id: 'MU',
    name: {
      en: 'Mauritius',
      de: 'Mauritius'
    }
  },
  MV: {
    id: 'MV',
    name: {
      en: 'Maldives',
      de: 'Malediven'
    }
  },
  MW: {
    id: 'MW',
    name: {
      en: 'Malawi',
      de: 'Malawi'
    }
  },
  MX: {
    id: 'MX',
    name: {
      en: 'Mexico',
      de: 'Mexiko'
    }
  },
  MY: {
    id: 'MY',
    name: {
      en: 'Malaysia',
      de: 'Malaysia'
    }
  },
  MZ: {
    id: 'MZ',
    name: {
      en: 'Mozambique',
      de: 'Mosambik'
    }
  },
  NA: {
    id: 'NA',
    name: {
      en: 'Namibia',
      de: 'Namibia'
    }
  },
  NC: {
    id: 'NC',
    name: {
      en: 'New Caledonia',
      de: 'Neukaledonien'
    }
  },
  NE: {
    id: 'NE',
    name: {
      en: 'Niger',
      de: 'Niger'
    }
  },
  NF: {
    id: 'NF',
    name: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel'
    }
  },
  NG: {
    id: 'NG',
    name: {
      en: 'Nigeria',
      de: 'Nigeria'
    }
  },
  NI: {
    id: 'NI',
    name: {
      en: 'Nicaragua',
      de: 'Nicaragua'
    }
  },
  NL: {
    id: 'NL',
    name: {
      en: 'Netherlands',
      de: 'Niederlande'
    }
  },
  NO: {
    id: 'NO',
    name: {
      en: 'Norway',
      de: 'Norwegen'
    }
  },
  NP: {
    id: 'NP',
    name: {
      en: 'Nepal',
      de: 'Nepal'
    }
  },
  NR: {
    id: 'NR',
    name: {
      en: 'Nauru',
      de: 'Nauru'
    }
  },
  NU: {
    id: 'NU',
    name: {
      en: 'Niue',
      de: 'Niue'
    }
  },
  NZ: {
    id: 'NZ',
    name: {
      en: 'New Zealand',
      de: 'Neuseeland'
    }
  },
  OM: {
    id: 'OM',
    name: {
      en: 'Oman',
      de: 'Oman'
    }
  },
  PA: {
    id: 'PA',
    name: {
      en: 'Panama',
      de: 'Panama'
    }
  },
  PE: {
    id: 'PE',
    name: {
      en: 'Peru',
      de: 'Peru'
    }
  },
  PF: {
    id: 'PF',
    name: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien'
    }
  },
  PG: {
    id: 'PG',
    name: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea'
    }
  },
  PH: {
    id: 'PH',
    name: {
      en: 'Philippines',
      de: 'Philippinen'
    }
  },
  PK: {
    id: 'PK',
    name: {
      en: 'Pakistan',
      de: 'Pakistan'
    }
  },
  PL: {
    id: 'PL',
    name: {
      en: 'Poland',
      de: 'Polen'
    }
  },
  PM: {
    id: 'PM',
    name: {
      en: 'Saint Pierre and Miquelon',
      de: 'St. Pierre und Miquelon'
    }
  },
  PN: {
    id: 'PN',
    name: {
      en: 'Pitcairn Islands',
      de: 'Pitcairn'
    }
  },
  PR: {
    id: 'PR',
    name: {
      en: 'Puerto Rico',
      de: 'Puerto Rico'
    }
  },
  PS: {
    id: 'PS',
    name: {
      en: 'Palestinian Territories',
      de: 'Palästinensische Gebiete'
    }
  },
  PT: {
    id: 'PT',
    name: {
      en: 'Portugal',
      de: 'Portugal'
    }
  },
  PW: {
    id: 'PW',
    name: {
      en: 'Palau',
      de: 'Palau'
    }
  },
  PY: {
    id: 'PY',
    name: {
      en: 'Paraguay',
      de: 'Paraguay'
    }
  },
  QA: {
    id: 'QA',
    name: {
      en: 'Qatar',
      de: 'Katar'
    }
  },
  QO: {
    id: 'QO',
    name: {
      en: 'Outlying Oceania',
      de: 'Äußeres Ozeanien'
    }
  },
  QU: {
    id: 'QU',
    name: {
      en: 'European Union',
      de: 'Europäische Union'
    }
  },
  RE: {
    id: 'RE',
    name: {
      en: 'Réunion',
      de: 'Réunion'
    }
  },
  RO: {
    id: 'RO',
    name: {
      en: 'Romania',
      de: 'Rumänien'
    }
  },
  RS: {
    id: 'RS',
    name: {
      en: 'Serbia',
      de: 'Serbien'
    }
  },
  RU: {
    id: 'RU',
    name: {
      en: 'Russia',
      de: 'Russische Föderation'
    }
  },
  RW: {
    id: 'RW',
    name: {
      en: 'Rwanda',
      de: 'Ruanda'
    }
  },
  SA: {
    id: 'SA',
    name: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien'
    }
  },
  SB: {
    id: 'SB',
    name: {
      en: 'Solomon Islands',
      de: 'Salomonen'
    }
  },
  SC: {
    id: 'SC',
    name: {
      en: 'Seychelles',
      de: 'Seychellen'
    }
  },
  SD: {
    id: 'SD',
    name: {
      en: 'Sudan',
      de: 'Sudan'
    }
  },
  SE: {
    id: 'SE',
    name: {
      en: 'Sweden',
      de: 'Schweden'
    }
  },
  SG: {
    id: 'SG',
    name: {
      en: 'Singapore',
      de: 'Singapur'
    }
  },
  SH: {
    id: 'SH',
    name: {
      en: 'Saint Helena',
      de: 'St. Helena'
    }
  },
  SI: {
    id: 'SI',
    name: {
      en: 'Slovenia',
      de: 'Slowenien'
    }
  },
  SJ: {
    id: 'SJ',
    name: {
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen'
    }
  },
  SK: {
    id: 'SK',
    name: {
      en: 'Slovakia',
      de: 'Slowakei'
    }
  },
  SL: {
    id: 'SL',
    name: {
      en: 'Sierra Leone',
      de: 'Sierra Leone'
    }
  },
  SM: {
    id: 'SM',
    name: {
      en: 'San Marino',
      de: 'San Marino'
    }
  },
  SN: {
    id: 'SN',
    name: {
      en: 'Senegal',
      de: 'Senegal'
    }
  },
  SO: {
    id: 'SO',
    name: {
      en: 'Somalia',
      de: 'Somalia'
    }
  },
  SR: {
    id: 'SR',
    name: {
      en: 'Suriname',
      de: 'Suriname'
    }
  },
  ST: {
    id: 'ST',
    name: {
      en: 'São Tomé and Príncipe',
      de: 'São Tomé und Príncipe'
    }
  },
  SV: {
    id: 'SV',
    name: {
      en: 'El Salvador',
      de: 'El Salvador'
    }
  },
  SY: {
    id: 'SY',
    name: {
      en: 'Syria',
      de: 'Syrien'
    }
  },
  SZ: {
    id: 'SZ',
    name: {
      en: 'Swaziland',
      de: 'Swasiland'
    }
  },
  TC: {
    id: 'TC',
    name: {
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln'
    }
  },
  TD: {
    id: 'TD',
    name: {
      en: 'Chad',
      de: 'Tschad'
    }
  },
  TF: {
    id: 'TF',
    name: {
      en: 'French Southern Territories',
      de: 'Französische Süd- und Antarktisgebiete'
    }
  },
  TG: {
    id: 'TG',
    name: {
      en: 'Togo',
      de: 'Togo'
    }
  },
  TH: {
    id: 'TH',
    name: {
      en: 'Thailand',
      de: 'Thailand'
    }
  },
  TJ: {
    id: 'TJ',
    name: {
      en: 'Tajikistan',
      de: 'Tadschikistan'
    }
  },
  TK: {
    id: 'TK',
    name: {
      en: 'Tokelau',
      de: 'Tokelau'
    }
  },
  TL: {
    id: 'TL',
    name: {
      en: 'Timor-Leste',
      de: 'Osttimor'
    }
  },
  TM: {
    id: 'TM',
    name: {
      en: 'Turkmenistan',
      de: 'Turkmenistan'
    }
  },
  TN: {
    id: 'TN',
    name: {
      en: 'Tunisia',
      de: 'Tunesien'
    }
  },
  TO: {
    id: 'TO',
    name: {
      en: 'Tonga',
      de: 'Tonga'
    }
  },
  TR: {
    id: 'TR',
    name: {
      en: 'Turkey',
      de: 'Türkei'
    }
  },
  TT: {
    id: 'TT',
    name: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago'
    }
  },
  TV: {
    id: 'TV',
    name: {
      en: 'Tuvalu',
      de: 'Tuvalu'
    }
  },
  TW: {
    id: 'TW',
    name: {
      en: 'Taiwan',
      de: 'Taiwan'
    }
  },
  TZ: {
    id: 'TZ',
    name: {
      en: 'Tanzania',
      de: 'Tansania'
    }
  },
  UA: {
    id: 'UA',
    name: {
      en: 'Ukraine',
      de: 'Ukraine'
    }
  },
  UG: {
    id: 'UG',
    name: {
      en: 'Uganda',
      de: 'Uganda'
    }
  },
  UM: {
    id: 'UM',
    name: {
      en: 'U.S. Minor Outlying Islands',
      de: 'Amerikanisch-Ozeanien'
    }
  },
  US: {
    id: 'US',
    name: {
      en: 'United States',
      de: 'Vereinigte Staaten'
    }
  },
  UY: {
    id: 'UY',
    name: {
      en: 'Uruguay',
      de: 'Uruguay'
    }
  },
  UZ: {
    id: 'UZ',
    name: {
      en: 'Uzbekistan',
      de: 'Usbekistan'
    }
  },
  VA: {
    id: 'VA',
    name: {
      en: 'Vatican City',
      de: 'Vatikanstadt'
    }
  },
  VC: {
    id: 'VC',
    name: {
      en: 'Saint Vincent and the Grenadines',
      de: 'St. Vincent und die Grenadinen'
    }
  },
  VE: {
    id: 'VE',
    name: {
      en: 'Venezuela',
      de: 'Venezuela'
    }
  },
  VG: {
    id: 'VG',
    name: {
      en: 'British Virgin Islands',
      de: 'Britische Jungferninseln'
    }
  },
  VI: {
    id: 'VI',
    name: {
      en: 'U.S. Virgin Islands',
      de: 'Amerikanische Jungferninseln'
    }
  },
  VN: {
    id: 'VN',
    name: {
      en: 'Vietnam',
      de: 'Vietnam'
    }
  },
  VU: {
    id: 'VU',
    name: {
      en: 'Vanuatu',
      de: 'Vanuatu'
    }
  },
  WF: {
    id: 'WF',
    name: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna'
    }
  },
  WS: {
    id: 'WS',
    name: {
      en: 'Samoa',
      de: 'Samoa'
    }
  },
  YE: {
    id: 'YE',
    name: {
      en: 'Yemen',
      de: 'Jemen'
    }
  },
  YT: {
    id: 'YT',
    name: {
      en: 'Mayotte',
      de: 'Mayotte'
    }
  },
  ZA: {
    id: 'ZA',
    name: {
      en: 'South Africa',
      de: 'Südafrika'
    }
  },
  ZM: {
    id: 'ZM',
    name: {
      en: 'Zambia',
      de: 'Sambia'
    }
  },
  ZW: {
    id: 'ZW',
    name: {
      en: 'Zimbabwe',
      de: 'Simbabwe'
    }
  }
};
