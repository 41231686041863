import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CalcRequest } from '../response/calculation';

@Injectable()
export class SearchService {

    private subject = new Subject<CalcRequest>();
    private closeSubject = new Subject<boolean>();
    private headerSubject = new Subject<CalcRequest>();

    missionSubject = this.subject.asObservable();
    missionCloseSubject = this.closeSubject.asObservable();
    missionHeaderSubject = this.headerSubject.asObservable();

    run(req: CalcRequest) {
        this.subject.next(req);
    }

    toggleHeaderForm(req: CalcRequest) {
        this.headerSubject.next(req);
    }

    close() {
        this.closeSubject.next(true);
    }
}
