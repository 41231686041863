import { ListingData } from '../response/calculation';

export interface CountryAgg {
    [key: string]: number;
}

export interface ModelAgg {
    [key: string]: ModelAggSource;
}

export interface ModelAggSource {
    name?: string;
    observations?: number;
}

export class ListingCollection {
    private _data: ListingData[];
    private _unused: number[];

    constructor(data: ListingData[], unused: number[]) {
        this._data = data;
        this._unused = unused;
    }

    countryCounts(): CountryAgg {
        return this.filteredItems().reduce((result: CountryAgg, item: ListingData): CountryAgg => {
            result[item.country] = result.hasOwnProperty(item.country)
                ? result[item.country] + 1
                : 1;

            return result;
        }, {});
    }

    modelObservations(): ModelAggSource[] {
        return Object.values(this.filteredItems().reduce((result: ModelAgg, item: ListingData): ModelAgg => {
            result[item.name] = result.hasOwnProperty(item.name)
                ? {name: item.name, observations: result[item.name].observations + 1}
                : {name: item.name, observations: 1};

            return result;
        }, {})).sort((a: ModelAggSource, b: ModelAggSource) => {
            return b.observations - a.observations;
        });
    }

    filteredItems(): ListingData[] {
        return this._data.filter((item: ListingData) => this._unused.indexOf(item.id) === -1);
    }
}
